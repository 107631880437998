import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Col } from "react-bootstrap";
import { routehelp } from "../src/Component/config/routehelp";
import HomePage from "./../src/Component/screens/index";
import GameList from "./../src/Component/screens/index";
import MyContestList from "../src/Component/screens/myContest/myContestList";
import ContestList from "../src/Component/screens/contestsList";
import Teamregistration from "../src/Component/screens/teamregistration";
import Contestdetails from "../src/Component/screens/contestdetails";
import Tournamentlist from "../src/Component/screens/tournamentlist";
import TournamentDetails from "./Component/screens/tournamentdetails";
import {PageNotFound} from "./Component/screens/Pagenotfound";
function App() {
  const [nav, setNav] = useState(false);
  const location = useLocation();
  const navMenuClick = () => {
    setNav(!nav);
  };

  return (
    <div className="row no-gutters d-flex justify-content-center main">
      <Col className="h-100 wrapper">
        <Routes>
          <Route
            exact
            path={routehelp.default}
            element={<HomePage navMenuClick={navMenuClick} />}
          />
          <Route
            exact
            path={routehelp.gametype}
            element={<GameList navMenuClick={navMenuClick} />}
          />
          <Route
            exact
            path={routehelp.mycontestlist}
            element={<MyContestList navMenuClick={navMenuClick} />}
          />
          <Route
            exact
            path={routehelp.contestList}
            element={<ContestList navMenuClick={navMenuClick} />}
          /> 
          <Route
            exact
            path={routehelp.teamregistration}
            element={<Teamregistration navMenuClick={navMenuClick} />}
          /> 
          <Route
            exact
            path={routehelp.contestdetails}
            element={<Contestdetails navMenuClick={navMenuClick} />}
          />
          <Route
            exact
            path={routehelp.tournamentlist}
            element={<Tournamentlist navMenuClick={navMenuClick} />}
          />
          <Route
            exact
            path={routehelp.tournamentdetails}
            element={<TournamentDetails navMenuClick={navMenuClick} />}
          /> 
          <Route
            exact
            path={routehelp.pagenotfound}
            element={<PageNotFound navMenuClick={navMenuClick} />}
          />
        </Routes>
      </Col>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
} from "@mui/material";

import Header from "../../../Component/Home/header";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader";
import ContestTab from "./contest-tab";
import TournamentTab from "./tournament-tab";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box className="tab-content">{children}</Box>}
    </div>
  );
};

const MyContestList = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(-1);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue || 0);
  };
  const handleBackClick = () => {
    navigate(-1)
  };
  useEffect(() => {
    setSelectedTab(0);
  }, [])

  //pagination
  const [lazyLoading, setLoader] = useState(false);

  return (

    <Header title={localStorage.getItem("selectedGameName")} onBackClick={handleBackClick}>
      {lazyLoading ? <Loader /> : <></>}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        className="tabs-container"
        centered
      >
        <Tab label="CONTESTS" className="tab" />
        <Tab label="TOURNAMENTS" className="tab" />
      </Tabs>

      <TabPanel value={selectedTab} index={0} >
        <ContestTab tab={selectedTab} setLoader={setLoader} />
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <TournamentTab tab={selectedTab} setLoader={setLoader} />
      </TabPanel>


    </Header>
  );
};


export default MyContestList;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Button,
  Divider,
  Tabs,
  Tab,
  capitalize,
} from "@mui/material";
import Header from "../../Component/Home/header";
import Tournaments from "../../../src/assets/images/Tournaments.png";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as Circlesvg } from "../../../src/assets/images/circle.svg";
import { ReactComponent as Clipcopy } from "../../../src/assets/images/clipcopy.svg";
import { ReactComponent as Iconsvg } from "../../../src/assets/images/icon.svg";
import { ReactComponent as Sharewithfrnds } from "../../../src/assets/images/sharewithfrnds.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { contestInfoApi, contestPlayersApi, contestRulesApi } from "../../apis";
import WinningBreakup from "../Popup/WinningBreakup";

import moment from "moment";
import url from "../../constants/url";

import Loader from "../loader";

import { RWebShare } from "react-web-share";
import ContestPlayers from "./contest-details/contest-players";
import { formatDate, formatTime, shareLink } from "../Utilities/helper";
import { addTrackData, trackerEventId } from "../../config/tracker";

const TabPanel = (props) => {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box className="tab-content">{children}</Box>}
    </div>
  );
};

const Contestdetails = () => {
  const data = [
    { username: "Sujeet2700", winnings: "2000", kills: "-", ranks: "-" },
    { username: "Player1", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player2", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player3", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player4", winnings: "2000", kills: "5", ranks: "Silver" },
    { username: "Sujeet2700", winnings: "2000", kills: "-", ranks: "-" },
    { username: "Player1", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player2", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player3", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player4", winnings: "2000", kills: "5", ranks: "Silver" },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoader] = useState(false);

  // const redFillStart = percentageFilled; // Start red fill after the percentageFilled
  const redFillWidth = 10; // Width of the red fill
  const [percentageFilled, setPercentageFilled] = useState(0);
  const [percentageRedFillWidth, setRedFillWidthFilled] = useState(0);

  // handle functions
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  const handleBackClick = () => {
    navigate(-1)
  };

  useEffect(() => {
    getContestInfo();
  }, [])

  
  useEffect(() => {
    if (selectedTab === 0) {
      getContestInfo();
    }
    else if (selectedTab === 1) {
        setContestPlayers(null);
    }
    else if (selectedTab === 2) {
      getContestRules();
    }
}, [selectedTab])

  
  const [contestDetail, setContestDetails] = useState(null);
  const [contestRules, setContestRules] = useState('');
  const [contestPlayers, setContestPlayers] = useState(null);
  const [isPricePointPoolModel, setPricePointPool] = useState(false);
  const [captainInfoCardData, setCaptainInfoCard] = useState([{ username: "Sujeet2700", kills: "-", ranks: "-" }]);

  async function getContestInfo() {
    setIsLoader(true);
    try {
      let response = await contestInfoApi(id, { filter: { user: JSON?.parse(localStorage?.getItem('profile'))?.user_id } });
      if (response?.item && response?.success) {
        setIsLoader(false);
        setContestDetails(response.item);
        setPercentageFilled((response?.item?.totalJoinedPlayers / response?.item?.totalPlayers) * 100)
        setRedFillWidthFilled(response?.item?.totalJoinedPlayers >= response?.item?.minimumSlot ? 0 : ((response?.item?.minimumSlot || 0) / response?.item?.totalPlayers) * 100);
        if (response?.captainInfo && response?.captainInfo?.user !== '') {
          setCaptainInfoCard([
            { username: response?.captainInfo?.userName, kills: response?.captainInfo?.kills > 0 ? response?.captainInfo?.kills : "-", ranks: response?.captainInfo?.rank > 0 ? response?.captainInfo?.rank : "-" }
          ])
        }
        addTrackData(trackerEventId?.Contest_Details_Shown,{gameCatalogName:`${localStorage.getItem("selectedGameName")}`,gameCatalogId:`${localStorage.getItem("game")}`,contestId:`${response?.item?._id}`});
      }
      else {
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      //console.log("getTournament error");
    }
  }
 

  

  async function getContestRules() {
    setIsLoader(true);
    try {
      let response = await contestRulesApi(id);
      if (response?.rules && response?.success) {
        setIsLoader(false);
        setContestRules(response.rules);
      }
      else {
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      //console.log("getTournament error");
    }
  }
  return (
   
      <Header title="Contest Details" onBackClick={handleBackClick}>
      {isLoading ? <Loader /> : <></>}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        className="tabs-container player-details-tabs"
        centered
      >
        <Tab label="Details" className="tab" />
        <Tab label="Players" className="tab" />
        <Tab label="Rules" className="tab" />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <div className="contestdetailscroll">
          <Box className="tab-panel-box">
            {/* Superpower Section */}
            <div className="superpower-container">
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  className="superpower-wapper"
                >
                  <div item className="col-8 superpower-box">
                    <div className="superpower-title">{contestDetail?.title?.length > 30 ? <marquee>{contestDetail?.title}</marquee> : contestDetail?.title}</div>
                  </div>

                  <div
                    item
                    className="col-4 superpower-box superpower-box-1 alignitemcenter"
                  >
                    <Button
                      variant="contained"
                      className="completed-button font-14"
                      disableElevation
                      disableTouchRipple
                      disableFocusRipple
                      disabled
                    >
                      {contestDetail?.status ? capitalize(contestDetail?.status) :''}
                    </Button>
                  </div>
                </Grid>
              </div>

            {/* Details Grid */}
            <div className="details-container">
  <div className="row contest-detail-grid">
      <div className="col-3 details-grid-box">
        <div className="details-label spacing-80">Date</div>
        <div className="details-value spacing-80">
          {formatDate(contestDetail?.date)}
        </div>
      </div>
      <div className="col-3 details-grid-box">
        <div className="details-label spacing-80">Time</div>
        <div className="details-value spacing-80">
          {formatTime(contestDetail?.time)}
        </div>
      </div>
      {contestDetail?.titles?.[0]?.isSelection && contestDetail?.titles?.[0]?.name && contestDetail?.titles?.[0]?.value && (
        <div className="col-3 details-grid-box">
          <div className="details-label spacing-80">{contestDetail?.titles?.[0]?.name}</div>
          <div className="details-value spacing-80 truncate">
            {contestDetail?.titles?.[0]?.value}
          </div>
        </div>
      )}
      {contestDetail?.titles?.[1]?.isSelection && contestDetail?.titles?.[1]?.name && contestDetail?.titles?.[1]?.value && (
        <div className="col-3 details-grid-box">
          <div className="details-label spacing-80">{contestDetail?.titles?.[1]?.name}</div>
          <div className="details-value spacing-80">
            {contestDetail?.titles?.[1]?.value}
          </div>
        </div>
      )}
    </div>

    <div className="row mt-3">
      <div className="col-3 details-grid-box mt-2">
        <div className="details-label">Prize Pool</div>
        <div className="d-flex align-items-center">
  <span className="icon me-1">
    <img src={url?.image_url + contestDetail?.currency?.[0]?.outCurrency?.img?.default} alt="" className="icon-14" />
  </span>
 {contestDetail?.prizePool}
</div>

      </div>
      <div className="col-9 details-grid-box">
        <div className="row">
          <div className="col-5 details-grid-box mt-2">
            <div className="details-label">{contestDetail?.winningModel == "perKill" ? "Per Kill" : "Winners"}</div>
            <div className="d-flex align-items-center">
              <div className="me-1">
                {contestDetail?.winningModel === "perKill" ? (
                  <span className="icon me-1">
                    <img src={url?.image_url + contestDetail?.currency?.[0]?.outCurrency?.img?.default} alt="" className="icon-14" />
                  </span>
                ) : null}
                {contestDetail?.winningModel == "perKill" ? contestDetail?.killPoints : contestDetail?.totalWinners}
              </div>
              {contestDetail?.winningModel != "perKill" && (
                <div onClick={(e) => { e.stopPropagation(); setPricePointPool(); }}>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.164 12.4927L2.828 7.35586L2.828 5.82268L4.63538 5.82268L8.164 9.22287L11.6926 5.82268L13.4973 5.82268L13.5 7.35585L8.164 12.4927Z" fill="#E8F3F5"/>
                  </svg>
                </div>
              )}
            </div>
          </div>
          <div className="col-7 details-grid-box mt-2">
            <div className="details-label">Join Using</div>
            <div>
              {contestDetail?.entryFee > 0 ? (
                <span className="icon me-2">
                  <img src={url.image_url + contestDetail?.currency?.[0]?.inCurrency?.img?.default} alt="" className="icon-14" />
                </span>
              ) : null}
              {contestDetail?.entryFee > 0 ? contestDetail?.entryFee : "Free"}
            </div>
          </div>
        </div>
      </div>
  

      <Grid container spacing={2} className="details-grid slots-info">
            <Grid item xs={12} className="details-grid-box">
              <div className="progress-bar-wrapper mt-3">
                <LinearProgress
                  variant="determinate"
                  value={percentageFilled || 0}
                  className="custom-linear-progress"
                />
                <Tooltip
                  title={`Minimum ${contestDetail?.minimumSlot || 0} Slot Required`}
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#1F3338",
                        border: "1px solid #87A7A6",
                        color: "#86A6A6", // Text color
                        fontSize: "0.75rem",
                        filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                        borderRadius: "4px",
                        padding: "8px",
                        width: "162px",
                        height: "50.758px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#1F3338",
                      },
                    },
                  }}
                  classes={{ tooltip: "custom-tooltip" }}>
                  <div
                    className="progress-bar-red-fill"
                    style={{
                      width: `${redFillWidth || 0}%`,
                      left: `${percentageFilled || 0}%`,
                      position: "absolute",
                      height: "100%",
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6} className="details-grid-box mt-1">
              <div className="slots-remaining">{contestDetail?.totalPlayers - contestDetail?.totalJoinedPlayers} slots remaining</div>
            </Grid>
            <Grid item xs={6} textAlign="right" className="details-grid-box mt-1">
              <div className="slots-filled">{contestDetail?.totalJoinedPlayers} slots filled</div>
            </Grid>
          </Grid>

          <div className="roomidpass-container">
            <Grid container spacing={2} className="details-grid slots-info">
              <Grid
                item
                xs={6}
                textAlign="center"
                className="details-grid-box"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(contestDetail?.roomId);
                }}>
                <div className="room-id-label details-label">Room ID</div>
                <div className="room-id-value">
                  {contestDetail?.roomId !== "" ? (
                    <>
                      {" "}
                      {
                        contestDetail?.roomId?.length >15 ? 
                        <marquee> {contestDetail?.roomId}</marquee>
                        :contestDetail?.roomId 
                      }{" "}
                      <span>
                        <Clipcopy />
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                textAlign="center"
                className="details-grid-box details-grid-box-1"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(contestDetail?.roomPassword);
                }}>
                <div className="password-label details-label">Password</div>
                <div className="room-id-value">
                  {contestDetail?.roomPassword !== "" ? (
                    <>
                      {
                        contestDetail?.roomPassword?.length >15 ? 
                        <marquee> {contestDetail?.roomPassword}</marquee>
                        :contestDetail?.roomPassword 
                      }{" "}
                      <span>
                        <Clipcopy />
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        
          </div>
          </div>

            {/* roomid pass */}
          </Box>
          <Box className="tab-panel-box">
            {/* Superpower Section */}
            <div className="superpower-container borderradius">
              <table
                className="superpower-wapper"
                style={{ width: "100%", textAlign: "center" }}
              >
                <thead style={{ background: "#8CFF05" }}>
                  <tr>
                    <th
                      className="tableheading"
                      style={{
                        color: "white",
                        padding: "16px",
                        textAlign: "left",
                      }}
                    >
                      Username
                    </th>

                    <th
                      className="tableheading"
                      style={{
                        color: "white",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      Kills
                    </th>
                    <th
                      className="tableheading"
                      style={{
                        color: "white",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      Ranks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {captainInfoCardData.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                       
                        backgroundColor: "transparent",
                      }}
                    >
                      <td
                        className="tablevalues"
                        style={{ padding: "13.5px 16px", textAlign: "left" }}
                      >
                        {item.username}
                      </td>

                      <td
                        className="tablevalues"
                        style={{ padding: "13.5px 16px", textAlign: "center" }}
                      >
                        {item.kills}
                      </td>
                      <td
                        className="tablevalues"
                        style={{ padding: "13.5px 16px", textAlign: "center" }}
                      >
                        {item.ranks}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Box>
          
          <RWebShare
            data = {shareLink(localStorage?.getItem('game'),contestDetail?.gameTypeAry?._id,contestDetail?.gameTypeAry?.name,contestDetail?._id,'contest')}
            // data={{
            //   text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
            //   url:
            //   `${url.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem('game')}&gameType=${contestDetail?.gameTypeAry?._id}&gameTypeName=${contestDetail?.gameTypeAry?.name}&type=contest`
            // }}
            sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
            onClick={() => {
              console.log("shared successfully!")
            }}
          >
            <button className="sharewithfrnds">
              <div className="sharewithfrnds-heading">Share With Friends</div>
              <div className="sharewithfrnds-icon">
                <Sharewithfrnds />
              </div>
            </button>
          </RWebShare>

        </div>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <ContestPlayers tab={selectedTab} contestId={id} contestDetail={contestDetail} />
        {/* <div className="contestdetailscroll">
          <Box className="tab-panel-box">
            <div className="body-scrolltable">
              <div className="superpower-container">
                <table
                  className="superpower-wapper"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <thead style={{ background: "#8CFF05" }}>
                    <tr>
                      <th
                        className="tableheading"
                        style={{
                          color: "white",
                          padding: "16px",
                          textAlign: "left",
                          width: showWinningsColumn ? "25%" : "33.33%", // Adjust width based on Winnings column visibility
                        }}
                      >
                        Username
                      </th>
                      {contestDetail?.status === "completed" && (
                        <th
                          className="tableheading"
                          style={{
                            color: "white",
                            padding: "16px",
                            textAlign: "center",
                            // width: "25%", // Fixed width for Winnings column
                          }}
                        >
                          Winnings
                        </th>
                      )}
                      <th
                        className="tableheading"
                        style={{
                          color: "white",
                          padding: "16px",
                          textAlign: "center", // Align Kills left if Winnings is shown, otherwise center
                          // width: "25%", // Fixed width for Kills column
                        }}
                      >
                        Kills
                      </th>
                      <th
                        className="tableheading"
                        style={{
                          color: "white",
                          padding: "16px",
                          textAlign: "center", // Align Ranks center if Winnings is shown, otherwise left
                          // width: "25%", // Fixed width for Ranks column
                        }}
                      >
                        Ranks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contestPlayers?.captainData ?
                      <tr
                        key={-1}
                        style={{
                          borderBottom:
                            "1px solid var(--core-neutral-dark, #415F66)",
                          padding: "13.5px 16px",
                          backgroundColor: "#415F66",
                        }}
                      >
                        <td
                          className="tablevalues"
                          style={{ padding: "13.5px 16px", textAlign: "left" }}
                        >
                          {contestPlayers?.captainData?.userName}
                        </td>
                        {contestDetail?.status === "completed" && (
                          <td
                            className="tablevalues"
                            style={{
                              padding: "13.5px 16px",
                              textAlign: "center",
                            }}
                          >
                            <span> <Iconsvg /></span> {contestPlayers?.captainData?.winnings === 0 ? '-' : contestPlayers?.captainData?.winnings}
                          </td>
                        )}
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}
                        >
                          {contestPlayers?.captainData?.kills === 0 ? '-' : contestPlayers?.captainData?.kills}
                        </td>
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}
                        >
                          {contestPlayers?.captainData?.rank === 0 ? '-' : contestPlayers?.captainData?.rank}
                        </td>
                      </tr> : ''

                    }
                    {contestPlayers?.data?.length > 0 ? contestPlayers?.data?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom:
                            "1px solid var(--core-neutral-dark, #415F66)",
                          padding: "13.5px 16px",
                          backgroundColor: "inherit",
                        }}
                      >
                        <td
                          className="tablevalues"
                          style={{ padding: "13.5px 16px", textAlign: "left" }}
                        >
                          {item.userName}
                        </td>
                        {contestDetail?.status === "completed" && (
                          <td
                            className="tablevalues"
                            style={{
                              padding: "13.5px 16px",
                              textAlign: "center",
                            }}
                          >
                            <span> <Iconsvg /></span> {item.winnings === 0 ? '-' : item.winnings}
                          </td>
                        )}
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}
                        >
                          {item.kills === 0 ? '-' : item.kills}
                        </td>
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}
                        >
                          {item?.rank === 0 ? '-' : item?.rank}
                        </td>
                      </tr>
                    )) : ''}
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
        </div> */}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <div className="contestdetailscroll">
          <div className="rules-list tab-panel-box" dangerouslySetInnerHTML={{ __html: contestRules }} />
        </div>
      </TabPanel>
      {isPricePointPoolModel && (
        <WinningBreakup
          isOpen={isPricePointPoolModel}
          title="Winning Breakup"
          onClose={() => setPricePointPool(false)}
          prizePool={contestDetail}
          tournaments={contestDetail}
        />
      )}
    </Header>
  );
};

export default Contestdetails;

import React from 'react';
import { Box } from '@mui/material'; // Assuming you are using Material UI's Box component
import Popup from './basepopup';
const QualificationPopup = ({ isOpen, onClose, selectedContestData }) => {
  return (
    <Popup
      isOpen={isOpen}
      title="Qualification"
      onClose={onClose}
    >
      <Box className="tab-panel-box">
        <div className="qualificationpopup">
          <div>Rank : 1 - {selectedContestData.winnerCount}</div>
          <div>Qualified For Next Round</div>
        </div>
        <svg
          className="custom-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="325"
          height="2"
          viewBox="0 0 325 2"
          fill="none"
        >
          <path
            opacity="0.45"
            d="M1 0.810059H324"
            stroke="#3C585B"
            strokeLinecap="round"
          />
        </svg>
      </Box>
    </Popup>
  );
};

export default QualificationPopup;

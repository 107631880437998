import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Button, capitalize } from "@mui/material";
import { ReactComponent as Clipcopy } from "../../../../src/assets/images/clipcopy.svg";
import Tournaments from "../../../../src/assets/images/Tournaments.png";

import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import url from "../../../constants/url";
import WinningBreakup from "../../Popup/WinningBreakup";
import { tournamentPricePointPoolApi } from "../../../apis";

import Loader from "../../loader";
import { formatDate } from "../../Utilities/helper";

const MyTournamentStructure = ({ handleTournamentClick, tournaments }) => {
  const [isPricePointPoolModel, setPricePointPool] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [prizePool, setPrizePool] = useState('');
  const [isLoading, setIsLoader] = useState(false);

  const totalSlots = tournaments.totalPlayers;
  const slotsFilled = tournaments.totalJoinedPlayers;
  const percentageFilled = (slotsFilled / totalSlots) * 100;
  const redFillWidth = tournaments?.totalJoinedPlayers >= tournaments?.minimumSlot ? 0 : ((tournaments?.minimumSlot || 0) / totalSlots) * 100; // Width of the red fill
  async function fetchPricePointPoolData(tournament) {
    setIsLoader(true);
    try {
      let response = await tournamentPricePointPoolApi(tournament?._id);
      if (response?.success) {
        setIsLoader(false);
        setPrizePool(response?.data);
      }
      else {
        setIsLoader(false);
        setPrizePool('');
      }
      setPricePointPool(true)
    } catch (error) {
      setIsLoader(false);
      setPrizePool('');
      console.log("getTournament error");
    }
  }

  //Handle Card Click this way due to event propogation issue
  const [clicked, setClicked] = useState(false);
  const handleContestClickPoint = () => {
    setClicked(true)  
    fetchPricePointPoolData(tournaments)
  };
  const handleContestClick = () => {
    if (!clicked) {
      handleTournamentClick(tournaments)
    }
    setClicked(false)  
  };
  
  return (
    isLoading ? <Loader />
    :
    <>
      <Box className="tab-panel-box"  onClick={() =>handleContestClick()}>
        {/* Superpower Section */}
        <div className="superpower-container">
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  className="superpower-wapper"
                >
                  <div item className="col-8 superpower-box">
                    <div className="superpower-title">{tournaments?.title?.length > 30 ? <marquee>{tournaments?.title}</marquee> : tournaments?.title}</div>
                  </div>

                  <div
                    item
                   
                    className="col-4 superpower-box superpower-box-1 alignitemcenter font-14"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      className="completed-button"
                      disableElevation
                    >
                      {capitalize(tournaments?.status)}
                    </Button>
                  </div>
                </Grid>
              </div>

        {/* Details Grid */}
        <div className="details-container">
        <Grid container spacing={2} className="details-grid">
                        <Grid item xs={4} className="details-grid paddingright">
                            <div className="image-container">
                            {tournaments?.featuredImage?.default ? (
                                <img src={url.image_url + tournaments?.featuredImage?.default} alt="" className="game-mode-img" />
                            ) : (
                                <img src={Tournaments} alt="" className="game-mode-img" />
                            )}
                            <span className="image-text">{tournaments?.gameTypeAry?.name}</span>
                            </div>
                        </Grid>

                        <Grid item xs={8} className="details-grid">
                            <Grid container spacing={2} className="details-grid marginBottom boxwidth">
                                <Grid item xs={5} className="details-grid-box w-100">
                                    <div className="details-label">Date</div>
                                    <div className="details-value">{formatDate(tournaments?.startDate)}</div>
                                </Grid>
                                <Grid item xs={4} className="details-grid-box">
                                    <div className="details-label">Rounds</div>
                                    <div className="details-value">{tournaments?.roundsCount}</div>
                                </Grid>
                                <Grid item xs={3} className="details-grid-box">
                                    <div className="details-label">ID</div>
                                    <div className="details-value" onClick={() => {
                                        navigator.clipboard.writeText(tournaments?.shortCode);
                                    }}>{tournaments?.shortCode}</div>
                                </Grid>
                            </Grid>
                            <Grid container className="details-grid slots-info">
                                {tournaments?.titles?.[0]?.isSelection && tournaments?.titles?.[0]?.name && tournaments?.titles?.[0]?.value &&
                                    <Grid item xs={5} className="details-grid-box">
                                        <div className="details-label">{tournaments?.titles?.[0]?.name}</div>
                                        <div className="details-value">{tournaments?.titles?.[0]?.value}</div>
                                    </Grid>
                                }
                                {tournaments?.titles?.[1]?.isSelection && tournaments?.titles?.[1]?.name && tournaments?.titles?.[1]?.value &&
                                    <Grid item xs={7} className="details-grid-box">
                                        <div className="details-label">{tournaments?.titles?.[1]?.name}</div>
                                        <div className="details-value">{tournaments?.titles?.[1]?.value}</div>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="details-grid">
                    <Grid item xs={4} className="details-grid-box">
                        <div className="details-label">Prize Pool</div>
                        <div>
                        {
                            tournaments?.prizePoolType === 'amount' &&
                            <span className="icon me-1">
                                <img
                                src={
                                    url?.image_url +
                                    tournaments?.currency?.outCurrency?.img?.default
                                }
                                alt=""
                                />
                            </span>
                        }
                        {tournaments?.prizePoolType === 'amount' ? tournaments?.prizePool : tournaments?.rewardDisplayText}
                        </div>
                    </Grid>
                    <Grid item xs={8} className="details-grid-box">
                        <Grid container spacing={2} className="details-grid">
                        <Grid item xs={5} className="details-grid-box">
                            <div className="details-label">Winners</div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="me-1">{tournaments?.totalWinners}</div>
                                <div   onClick={(e) => {
         e.stopPropagation();
         handleContestClickPoint();
     }}>
                                    <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M8.164 12.4927L2.828 7.35586L2.828 5.82268L4.63538 5.82268L8.164 9.22287L11.6926 5.82268L13.4973 5.82268L13.5 7.35585L8.164 12.4927Z"
                                        fill="#E8F3F5"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} className="details-grid-box">
                            <div className="details-label">Join Using</div>
                            <div>
                            {tournaments?.entryFee > 0 ? (
                                <span className="icon me-2">
                                    <img
                                    src={
                                        url.image_url +
                                        tournaments?.currency?.inCurrency?.img?.default
                                    }
                                    alt=""
                                    />
                                </span>
                            ) : (
                            ""
                            )}
                            {tournaments?.entryFee > 0 ? tournaments?.entryFee : "Free"}
                            </div>
                        </Grid>
                        </Grid>
                    </Grid>
                    </Grid>

          <Grid container spacing={2} className="details-grid slots-info">
            <Grid item xs={12} className="details-grid-box">
              <div className="progress-bar-wrapper">
                <LinearProgress
                  variant="determinate"
                  value={percentageFilled}
                  className="custom-linear-progress"
                />
                <Tooltip
                  title={`Minimum ${tournaments?.minimumSlot} slot required`}
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#1F3338",
                        border: "1px solid #87A7A6",
                        color: "#86A6A6", // Text color
                        fontSize: "0.75rem",
                        filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                        borderRadius: "4px",
                        padding: "8px",
                        width: "162px",
                        height: "50.758px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#1F3338",
                      },
                    },
                  }}
                  classes={{ tooltip: "custom-tooltip" }}>
                  <div
                    className="progress-bar-red-fill"
                    style={{
                      width: `${redFillWidth}%`,
                      left: `${percentageFilled}%`,
                      position: "absolute",
                      height: "100%",
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6} className="details-grid-box">
              <div className="slots-remaining">{tournaments?.totalPlayers - tournaments?.totalJoinedPlayers} slots remaining</div>
            </Grid>
            <Grid item xs={6} textAlign="right" className="details-grid-box">
              <div className="slots-filled">{tournaments?.totalJoinedPlayers} slots filled</div>
            </Grid>
          </Grid>

          <div className="roomidpass-container">
            <Grid container spacing={2} className="details-grid slots-info">
              <Grid
                item
                xs={6}
                textAlign="center"
                className="details-grid-box"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(tournaments?.contestsList?.roomId);
                }}>
                <div className="room-id-label details-label">Room ID</div>
                <div className="room-id-value">
                  {tournaments?.contestsList?.roomId !== "" ? (
                    <>
                      {" "}
                      {tournaments?.contestsList?.roomId?.length > 15 ? <marquee>{tournaments?.contestsList?.roomId}</marquee> : tournaments?.contestsList?.roomId}{" "}
                      <span>
                        <Clipcopy />
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                textAlign="center"
                className="details-grid-box details-grid-box-1"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(tournaments?.contestsList?.roomPassword);
                }}>
                <div className="password-label details-label">Password</div>
                <div className="room-id-value">
                  {tournaments?.contestsList?.roomPassword !== "" ? (
                    <>
                      {tournaments?.contestsList?.roomPassword?.length > 15 ? <marquee>{tournaments?.contestsList?.roomPassword}</marquee> : tournaments?.contestsList?.roomPassword}{" "}
                      <span>
                        <Clipcopy />
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        {isPricePointPoolModel && (
        <WinningBreakup
          isOpen={isPricePointPoolModel}
          title="Winning Breakup"
          onClose={() => setPricePointPool(false)}
          prizePool={ prizePool}
          tournaments={selectedItem}
        />
      )}
      </Box>
    </>
  );
};

export default MyTournamentStructure;

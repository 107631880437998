import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Button,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import Header from "../../Component/Home/header";
import Tournaments from "../../../src/assets/images/Tournaments.png";
import Tooltip from "@mui/material/Tooltip";
import Popup from "../Popup/basepopup";
import RulesPopup from "../Popup/Rules";
import { ReactComponent as Iconsvg } from "../../../src/assets/images/icon.svg";
import { tournamentListApi, tournamentByCode } from "../../apis";
import TournamentStructure from "./tournamentStructure";
import ToastPopup from "../Popup/ToastPopup";
import { useNavigate } from "react-router";
import Loader from "../loader";
import { NotDataFound } from "./Pagenotfound";
import { addTrackData, trackerEventId } from "../../config/tracker";

const rulesData = [
  "A number of rooms will be created to accommodate 400 Players. Room joining will be first come first serve. Gamerji is not responsible if the room is full and the user does not get a chance to play.",
  "Tournament Qualifications & Winning declarations are based on point calculations. See the details below on how the points are calculated.",
  "The room ID and password for the game will be provided 15 mins before the start time of the contest through notifications, email, or SMS. However, we recommend users to check the Gamerji app tournament section for the ID/Password. Gamerji is not responsible for the delay or failure of delivery of the ID/Password.",
  "A number of rooms will be created to accommodate 400 Players. Room joining will be first come first serve. Gamerji is not responsible if the room is full and the user does not get a chance to play.",
  "Tournament Qualifications & Winning declarations are based on point calculations. See the details below on how the points are calculated.",
  "The room ID and password for the game will be provided 15 mins before the start time of the contest through notifications, email, or SMS. However, we recommend users to check the Gamerji app tournament section for the ID/Password. Gamerji is not responsible for the delay or failure of delivery of the ID/Password.",
  "A number of rooms will be created to accommodate 400 Players. Room joining will be first come first serve. Gamerji is not responsible if the room is full and the user does not get a chance to play.",
];


const Tournamentlist = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isRulesPopup, setIsRulesPopup] = useState(true);
  const [isPopupOpenTournament, setPopupOpenTournament] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToastErr, setShowToastErr] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [totalTournament, setTotalTournament] = useState(0);
  const [tournamentPage, setTournamentPage] = useState(1);
  const [tournamentCode, setTournamentCode] = useState('');
  const [joinViaCodeTournament, setJoinViaCodeTournament] = useState({});
  const [isLoading, setIsLoader] = useState(false);
  const navigate = useNavigate();

   //pagination
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPage, setTotalPage] = useState(-1);
   const [recordPerPage, setRecordsPerPage] = useState(5);
   const [lazyLoading, setLazyLoading] = useState(false);
   const [hasMore, setHasMore] = useState(true);
   useEffect(() => {
     if (currentPage) {
       if (!lazyLoading){
         fetchData(currentPage);
       }
     }
   }, [currentPage]);
   const handleScroll = () => {
     const container = document.getElementById('scroll-container');
     if (!container) return;
     const scrollTop = container.scrollTop;
     const scrollHeight = container.scrollHeight;
     const clientHeight = container.clientHeight;
     if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !lazyLoading) {
       setCurrentPage(currentPage + 1)
     }
   };
   useEffect(() => {
     const container = document.getElementById('scroll-container');
     if (container) {
       container.addEventListener('scroll', handleScroll);
       return () => {
         container.removeEventListener('scroll', handleScroll);
       };
     }
   }, [hasMore, lazyLoading]);

  useEffect(() => {
    console.log("showToast", showToast)
  }, [showToast])

  const toggleInputVisibility = () => {
    setIsInputVisible(!isInputVisible);
  };
  
  const handleApplyClick = async () => {
    // Function to close both the toast and overlay when clicking on bgmi-container
    setIsLoader(true);
    if (tournamentCode?.trim()) {
      try {
        let payload = {
          user: JSON?.parse(localStorage?.getItem('profile'))?.user_id,
          code: tournamentCode
        };
        let response = await tournamentByCode(payload);
        if (response?.success && response?.item) {
          setIsLoader(false);
          setJoinViaCodeTournament(response?.item);
          setTournamentCode('');
          setPopupOpenTournament(true);
          addTrackData(trackerEventId?.Tournament_Popup_Shown,{gameCatalogName:`${localStorage.getItem("selectedGameName")}`,gameCatalogId:`${localStorage.getItem("game")}`,tournamentId:`${response?.item?.id}`});
        } else {
          setIsLoader(false);
          setJoinViaCodeTournament({});
          setShowToast(true)
          setShowToastErr("Tournament not found");
        }
      } catch (error) {
        setIsLoader(false);
        setJoinViaCodeTournament({});
        setShowToast(true)
        console.log("getTournament error");
        setShowToastErr("Tournament not found")
      }
    }
    else {
      setIsLoader(false);
      setShowToast(true);
      setShowToastErr("Please enter invite code");
    }
  };

  const totalSlots = 80;
  const slotsFilled = 8;
  const percentageFilled = (slotsFilled / totalSlots) * 100;
  const redFillStart = percentageFilled; // Start red fill after the percentageFilled
  const redFillWidth = 10; // Width of the red fill

  const handleBackClick = () => {
    // navigate(-1)
    navigate("/");
  };
  // rounds

  useEffect(() => {
      //fetchData(1);
  }, []);

  async function fetchData(page) {
    setLazyLoading(true);
    if(page===1){
      setTournaments([]);
    }
    try {
      let payload = {
        skip: (page - 1) || 0,
        limit: recordPerPage,
        filter: {
          tournamentType: "gamerji",
          user: JSON?.parse(localStorage?.getItem('profile'))?.user_id,
          game: localStorage?.getItem('game'),
        },
      };

      let response = await tournamentListApi(payload);
      if (response?.data && response?.success) {
        setLazyLoading(false);
        setTotalPage(response?.totalPages || 0)
        if (response?.totalPages === page) {
          setHasMore(false)
        }
        if (page === 1) {
          setTournaments(response?.data);
          addTrackData(trackerEventId?.Tournaments_List_Shown,{gameCatalogName:`${response?.data[0]?.gameAry?.name}`,gameCatalogId:`${response?.data[0]?.gameAry?._id}`});
        } else {
          setTournaments([...tournaments, ...response?.data]);
        }
        setTotalTournament(response?.totalRecord)
      }
      else {
        setLazyLoading(false);
      }
    } catch (error) {
      setLazyLoading(false);
      console.log("getTournament error");
    }
  }

  return (
    <>
      <Header title="Tournaments" onBackClick={handleBackClick} >
      {lazyLoading && currentPage === 1 ? <Loader /> : <></>}
        {tournaments.length > 0 &&
          <Box className="invite-code-box">
            <div className="invite-code-header">
              <span>Register Via Invite Code</span>
              <span className="invite-code-arrow" onClick={toggleInputVisibility}>
                {isInputVisible ? (
                  // Arrow when the input is open (second SVG)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="18"
                    viewBox="0 0 12 18"
                    fill="none"
                  >
                    <path
                      d="M11.25 9L2.58595 18L2.69415e-06 18L2.5609e-06 14.9516L5.73496 9L2.0406e-06 3.04843L1.90754e-06 0.00447468L2.58595 3.78718e-07L11.25 9Z"
                      fill="#8CFF05"
                    />
                  </svg>
                ) : (
                  // Arrow when the input is closed (first SVG)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                  >
                    <path
                      d="M9 11.25L-3.78718e-07 2.58595L-4.91753e-07 2.69415e-06L3.04843 2.5609e-06L9 5.73496L14.9516 2.0406e-06L17.9955 1.90754e-06L18 2.58595L9 11.25Z"
                      fill="#8CFF05"
                    />
                  </svg>
                )}
              </span>
            </div>

            {isInputVisible && (
              <div className="invite-code-input-container">
                <input
                  type="text"
                  id="invite-code"
                  className="invite-code-input"
                  placeholder="Enter tournament code"
                  onChange={(e) => setTournamentCode(e.target.value)}
                  value={tournamentCode}
                />
                <button
                  className="invite-code-apply-button"
                  onClick={handleApplyClick}
                >
                  APPLY
                </button>
              </div>
            )}
          </Box>
        }
        {tournaments.length > 0 ?
          <div className="tournament-body-scrolltable" id="scroll-container" style={{ height: '100vh', overflowY: 'scroll' }}>
            {
              tournaments?.map((item, ind) => {
                return (
                  <>
                    <TournamentStructure
                      tournaments={item}
                    />
              
                  </>
                )
            })}
          </div>
          :
          <NotDataFound msg={"No games found"} />
        }

      {isPopupOpenTournament && (
        <Popup
          isOpen={isPopupOpenTournament}
          title={joinViaCodeTournament?.title}
          onClose={() => setPopupOpenTournament(false)}
        >
          <TournamentStructure
            tournaments={joinViaCodeTournament}
          />
        </Popup>
      )}
      
      {showToast && (
        <ToastPopup message ={showToastErr}  setShowToast= {setShowToast} />
      )}
      </Header>
    </>
  );
};

export default Tournamentlist;

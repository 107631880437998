import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyContestStructure from "../widgets/myContestStructure";
import { getMyContestApi } from "../../../apis";
import { NotDataFound } from "../Pagenotfound";

const ContestTab = ({ tab, setLoader }) => {
  console.log(tab);
  const [contestList, setContestList] = useState([]);
  const navigate = useNavigate();

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(-1);
  const [recordPerPage, setRecordsPerPage] = useState(5);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    if (currentPage) {
      if (!lazyLoading) {
        getMyContest(currentPage);
      }
    }
  }, [currentPage]);
  const handleScroll = () => {
    const container = document.getElementById("scroll-container");
    if (!container) return;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !lazyLoading) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    const container = document.getElementById("scroll-container");
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, lazyLoading]);

  const handleContestClick = (contest) => {
    navigate("/contest/details/" + contest?._id);
  };

  async function getMyContest(page) {
    setLazyLoading(true);
    if (page === 1) {
      setContestList([]);
      setLoader(true);
    }
    try {
      let data = {
        search: {
          game: localStorage?.getItem("game"),
          user: JSON?.parse(localStorage?.getItem("profile"))?.user_id,
          status: ["waiting", "started", "inprogress", "inProcess", "review", "cancelled", "completed"],
        },
        pagination: {
          pageNo: page,
          recordPerPage: recordPerPage,
          sortBy: "dateTime",
          sortDirection: "desc",
        },
      };
      let response = await getMyContestApi(data);
      setLazyLoading(false);
      if (page === 1) {
        setLoader(false);
      }
      if (response && response?.success) {
        setCurrentPage(page);
        setTotalPage(response?.totalPages || 0);
        if (response?.totalPages === page) {
          setHasMore(false);
        }
        setContestList([...contestList, ...response.data]);
      } else {
        setLazyLoading(false);
      }
    } catch (error) {
      if (page === 1) {
        setLoader(false);
      }
      setLazyLoading(false);
      console.log("getTournament error", error);
    }
  }

  return (
    <div id="scroll-container" style={{ height: "100vh", overflowY: "scroll" }} className="tournament-body-scrolltable">
      {contestList && contestList.length > 0 ? contestList?.map((contest, ind) => {
        return <MyContestStructure contest={contest} handleContestClick={handleContestClick} />;
      }) :<NotDataFound/>}
    </div>
  );
};
export default ContestTab;

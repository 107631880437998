import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Button, IconButton } from "@mui/material";
import Header from "../Home/header";
import ShareIcon from "@mui/icons-material/Share";
import Popup from "../Popup/basepopup";
import ContestStructure from "./contestStructure";
import { contestListApi, getContestById, getInGameNameByIdentifier, getMyContestApi, joinContestApi } from "../../apis";
import url from "../../constants/url";
import { useNavigate, useParams } from "react-router-dom";
import { routehelp } from "../config/routehelp";
import ToastPopup from "../Popup/ToastPopup";
import Loader from "../loader";
import { NotDataFound } from "./Pagenotfound";
import { addTrackData, trackerEventId } from "../../config/tracker";

const ContestList = () => {
  const navigate = useNavigate();
  let { gameTypeId } = useParams();

  const [showToast, setShowToast] = useState(false);
  const [showToastErr, setShowToastErr] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);

  const [selectedContest, setSelectedContest] = useState(null);
  const [contestsList, setContestList] = useState([]);
  const [totalContests, setTotalContests] = useState(0);
  const [contestCode, setContestCode] = useState("");
  const [uniqueIgn, setUniqueIgn] = useState("");
  const [isLoading, setIsLoader] = useState(true);


  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(-1);
  const [recordPerPage, setRecordsPerPage] = useState(5);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    if (currentPage) {
      if (!lazyLoading){
        fetchData(currentPage);
      }
    }
  }, [currentPage]);
  const handleScroll = () => {
    const container = document.getElementById('scroll-container');
    if (!container) return;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !lazyLoading) {
      setCurrentPage(currentPage + 1)
    }
  };
  useEffect(() => {
    const container = document.getElementById('scroll-container');
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [hasMore, lazyLoading]);


  const toggleInputVisibility = () => {
    setIsInputVisible(!isInputVisible);
  };

  const handleApplyClick = async () => {
    // Function to close both the toast and overlay when clicking on bgmi-container
    setIsLoader(true);
    if (contestCode?.trim()) {
      try {
        let payload = {
          code: contestCode,
          search: { user: JSON?.parse(localStorage?.getItem("profile"))?.user_id },
        };
        let response = await getContestById(payload);
        if (response?.success && response?.data) {
          setIsLoader(false);
          setSelectedContest(response?.data);
          setContestCode("");
          setIsPopupOpen(true);
          // event is here due to join popup from here if i put it into contest strucutre it execute everytime
          addTrackData(trackerEventId?.Contest_Join_Popup_Shown,{gameCatalogName:`${localStorage.getItem("selectedGameName")}`,gameCatalogId:`${localStorage.getItem("game")}`,contestId:`${response?.data?._id}`});
        } else {
          setIsLoader(false);
          setSelectedContest({});
          setShowToast(true);
          setShowToastErr("Contest not found");
        }
      } catch (error) {
        setIsLoader(false);
        setSelectedContest({});
        setShowToast(true);
        setShowToastErr("Contest not found");
        console.log("getTournament error");
      }
    }
    else {
      setIsLoader(false);
      setShowToast(true);
      setShowToastErr("Please enter invite code");
    }
  };

  //open join model
  const handleRegisterClick = (contest) => {
    setIsPopupOpen(!isPopupOpen);
    if (contest?.isJoined) {
      //if contest is join then go to detail page
      navigate("/contest/details/" + contest?._id);
      return;
    }
    setSelectedContest(contest);
  };

  const handleBackClick = () => {
    // navigate(-1);
    navigate("/");
  };
  useEffect(() => {
    //fetchData(1);
    InGameNameApiCall();
  }, []);

  async function fetchData(page) {
    setLazyLoading(true);
    if(page===1){
      setContestList([]);
    }
    try {
      let payload = {
       
        pagination:{
          pageNo:page,
          recordPerPage:recordPerPage,
          sortBy: "dateTime",
          sortDirection: "asc",
        },
        search: {
          game: localStorage?.getItem("game"),
          gameType : gameTypeId,
          user: JSON?.parse(localStorage?.getItem("profile"))?.user_id,
        },
      };

      let response = await contestListApi(payload);
      if (response?.data && response?.success) {
        setLazyLoading(false);
        setTotalPage(response?.totalPages || 0)
        if (response?.totalPages === page) {
          setHasMore(false)
        }
        if (page === 1) {
          setContestList(response?.data);
          addTrackData(trackerEventId?.Contest_List_Shown,{gameCatalogName:`${response?.data[0]?.gameAry[0]?.name}`,gameCatalogId:`${response?.data[0]?.gameAry[0]?._id}`});
        } else {
          setContestList([...contestsList, ...response?.data]);
        }
        setTotalContests(response?.totalRecord);
      }
    } catch (error) {
      setLazyLoading(false);
      console.log("getTournament error");
    }
  }
  const InGameNameApiCall = async () => {
    setIsLoader(true);
    let existsUserInfo = JSON?.parse(localStorage?.getItem('profile'));

    let payload = {
        "game": localStorage?.getItem("game")
    }

    if (existsUserInfo?.phoneNumber) {
        payload.user_name = existsUserInfo?.phoneNumber;
        payload.countryCode = existsUserInfo?.countryCode;
        payload.type = "phoneNo"
    }
    else {
      payload.user_name = existsUserInfo?.email;
      payload.type = "email"
    }

    let gameNamesData = await getInGameNameByIdentifier(payload);
    if (gameNamesData?.success) {
      setIsLoader(false);
      setUniqueIgn(gameNamesData?.data?.username || "");
    } else {
      setIsLoader(false);
      setUniqueIgn("");
    }
  };


  return (
   
      
      <Header title={localStorage.getItem("selectedGameMode")} onBackClick={handleBackClick}>
      {lazyLoading && currentPage===1 ? <Loader /> : <></>}
      {isLoading ? <Loader /> : <></>}
      {contestsList?.length > 0 &&
        <Box className="invite-code-box">
          <div className="invite-code-header">
            <span>Register Via Invite Code</span>
            <span className="invite-code-arrow" onClick={toggleInputVisibility}>
              {isInputVisible ? (
                // Arrow when the input is open (second SVG)
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
                  <path
                    d="M11.25 9L2.58595 18L2.69415e-06 18L2.5609e-06 14.9516L5.73496 9L2.0406e-06 3.04843L1.90754e-06 0.00447468L2.58595 3.78718e-07L11.25 9Z"
                    fill="#8CFF05"
                  />
                </svg>
              ) : (
                // Arrow when the input is closed (first SVG)
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                  <path
                    d="M9 11.25L-3.78718e-07 2.58595L-4.91753e-07 2.69415e-06L3.04843 2.5609e-06L9 5.73496L14.9516 2.0406e-06L17.9955 1.90754e-06L18 2.58595L9 11.25Z"
                    fill="#8CFF05"
                  />
                </svg>
              )}
            </span>
          </div>

          {isInputVisible && (
            <div className="invite-code-input-container">
              <input
                type="text"
                id="invite-code"
                className="invite-code-input"
                onChange={(e) => setContestCode(e.target.value)}
                value={contestCode}
                placeholder="Enter Contest Code"
              />
              <button className="invite-code-apply-button" onClick={handleApplyClick}>
                APPLY
              </button>
            </div>
          )}
        </Box>
      }
      {contestsList?.length > 0 ? (
        <div className="indiggscrolling" id="scroll-container" style={{ height: '100vh', overflowY: 'scroll' }}>
          {
            contestsList?.map((item, ind) => {
            return <ContestStructure handleRegisterClick={handleRegisterClick} contest={item} uniqueIgn={uniqueIgn} />;
          })}
        
        </div>
      ) : (
            <NotDataFound msg={"No games found"} />
      )}

      {isPopupOpen && (
        <Popup isOpen={isPopupOpen} title={selectedContest?.title} onClose={() => setIsPopupOpen(false)}>
          <ContestStructure contest={selectedContest} uniqueIgn={uniqueIgn} />
        </Popup>
      )}

      {showToast && <ToastPopup message={showToastErr} setShowToast={setShowToast} />}
    </Header>
  );
};

export default ContestList;

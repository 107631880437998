import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ title, onBackClick, children, background }) => {
  // console.log(background)
  return (
    <div className={`bgmi-container ${background ? "radial" :""}`} >
      <div className="header">
        <button className="back-button" onClick={onBackClick}>
          <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 17L3 12M3 12L8 7M3 12H21" stroke="#DBEDF1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <div className="title">{title?.toLowerCase()}</div>
      </div>

      {/* Scrollable body content */}
      <div className='inner-wrapper'>
      <div className="body-contentscrollable">
        {children}
      </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func,
  children: PropTypes.node,
};

Header.defaultProps = {
  onBackClick: () => {},
};

export default Header;

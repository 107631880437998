import axios from "axios";
import helperFunc from "../helperFuncs";

var header = localStorage.getItem('refreshToken') ? { 'x-access-token': localStorage.getItem('refreshToken'), 'user-jwt-token': localStorage.getItem('refreshToken') } : {"user-type": "appUser"};
header['company-code'] = 'IG';
header['language'] = localStorage.getItem('langName')?.toUpperCase() || 'EN';
let country = helperFunc.getCountryId()

if (country)
    header['country'] = country;

const refreshInstance = axios.create({
    timeout: 90000,
    headers: header
});

refreshInstance.interceptors.request.use(function (config) {
    if (!!config.data) {
        for (let key of Object.keys(config?.data)) {
            if (config.data[key] === "")
                config.data[key] = null
        }
    }
    return config;
}, function (error) {
    return error
});

refreshInstance.interceptors.response.use(function (response) {
    if (response.status === 200) {
        return response.data;
    }
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
    }
    return error?.response;
});

export default refreshInstance;
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { ReactComponent as Iconsvg } from "../../../../src/assets/images/icon.svg";

import {
  indiggTournamentDetailPlayers,
} from "../../../apis";
import Loader from "../../loader";


const TournamentPlayers = ({ tab, tournamentId, tournaments }) => {
  const [playerCaptainData, setPlayerCaptainData] = useState({});
  const [allPlayersData, setAllPlayersData] = useState([]);
   //pagination
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPage, setTotalPage] = useState(-1);
   const [recordPerPage, setRecordsPerPage] = useState(10);
   const [lazyLoading, setLazyLoading] = useState(false);
   const [hasMore, setHasMore] = useState(true);
   useEffect(() => {
     if (currentPage) {
       if (!lazyLoading){
        fetchPlayer(currentPage);
       }
     }
   }, [currentPage]);
   const handleScroll = () => {
     const container = document.getElementById('scroll-container');
     if (!container) return;
     const scrollTop = container.scrollTop;
     const scrollHeight = container.scrollHeight;
     const clientHeight = container.clientHeight;
     if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !lazyLoading) {
       setCurrentPage(currentPage + 1)
     }
   };
   useEffect(() => {
     const container = document.getElementById('scroll-container');
     if (container) {
       container.addEventListener('scroll', handleScroll);
       return () => {
         container.removeEventListener('scroll', handleScroll);
       };
     }
   }, [hasMore, lazyLoading]);


  const fetchPlayer = async (page) => {
    setLazyLoading(true);
    if (page === 1) {
        setAllPlayersData([]);
    }
    try{ 
        console.log(currentPage,recordPerPage)
      let payload = {
        skip:  ((currentPage-1)*recordPerPage),
        limit: recordPerPage,
        event: tournamentId,
        user: JSON?.parse(localStorage?.getItem('profile'))?.user_id,
        sortBy: "createdAt",
        sort: "desc",
      };

      const res = await indiggTournamentDetailPlayers(payload, tournamentId);
      if (res?.success) {
        var totalPage = Math.ceil(res?.count / recordPerPage);
        setCurrentPage(page);
        setTotalPage(totalPage || 0);
        if (totalPage === page) {
          setHasMore(false);
        }

        setLazyLoading(false);
        setPlayerCaptainData(res?.captainData)
        setAllPlayersData([...allPlayersData,...res?.list])
      }
      else {
        setLazyLoading(false);
      }
    }catch(e){
        setLazyLoading(false);
    }
  }

  return (
    (lazyLoading && currentPage == 1) ? <Loader />
    :
    <Box className="tab-panel-box">
      {/* Superpower Section */}
      <div className="body-scrolltable"  id="scroll-container" style={{   overflowY: 'scroll' }}>
        <div className="superpower-container borderradius">
          <table className="superpower-wapper" style={{ width: "100%", textAlign: "center" }}>
            <thead style={{ background: "#8CFF05" }}>
              <tr>
                <th
                  className="tableheading"
                  style={{
                    color: "white",
                    padding: "16px",
                    textAlign: "left",
                  }}>
                  Username
                </th>
                {tournaments?.status === "completed" && (
                  <th
                    className="tableheading"
                    style={{
                      color: "white",
                      padding: "16px",
                      textAlign: "center",
                    }}>
                    Winnings
                  </th>
                )}
                <th
                  className="tableheading"
                  style={{
                    color: "white",
                    padding: "16px",
                    textAlign: "center",
                  }}>
                  Kills
                </th>
                <th
                  className="tableheading"
                  style={{
                    color: "white",
                    padding: "16px",
                    textAlign: "center",
                  }}>
                  Ranks
                </th>

                <th
                  className="tableheading"
                  style={{
                    color: "white",
                    padding: "16px",
                    textAlign: "center",
                  }}>
                  Points
                </th>
              </tr>
            </thead>
            <tbody>
              {playerCaptainData && (
                <tr
                  style={{
                    // borderBottom: "1px solid var(--core-neutral-dark, #415F66)",
                    padding: "13.5px 16px",
                    textAlign: "left",
                    backgroundColor: "#415F66",
                  }}>
                  <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "left" }}>
                    {playerCaptainData?.user?.gamerjiName}
                  </td>
                  {tournaments?.status === "completed" && (
                    <td
                      className="tablevalues"
                      style={{
                        padding: "13.5px 16px",
                        textAlign: "center",
                      }}>
                      <span>
                        <Iconsvg />
                      </span>{" "}
                      {playerCaptainData?.totalAmount}
                    </td>
                  )}
                  <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "center" }}>
                    {playerCaptainData?.kills}
                  </td>
                  <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "center" }}>
                    {playerCaptainData?.rank}
                  </td>

                  <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "center" }}>
                    {playerCaptainData?.points}
                  </td>
                </tr>
                
              )}
              {allPlayersData.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    // borderBottom: "1px solid var(--core-neutral-dark, #415F66)",
                    padding: "13.5px 16px",
                    textAlign: "left",
                    backgroundColor: "inherit",
                  }}>
                  <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "left" }}>
                    {item?.user?.gamerjiName}
                  </td>
                  {tournaments?.status === "completed" && (
                    <td
                      className="tablevalues"
                      style={{
                        padding: "13.5px 16px",
                        textAlign: "center",
                      }}>
                      -
                    </td>
                  )}
                  <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "center" }}>
                    {item?.kills}
                  </td>
                  <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "center" }}>
                    {item?.rank}
                  </td>

                  <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "center" }}>
                    {item?.points}
                  </td>
                </tr>
                
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};
export default TournamentPlayers;

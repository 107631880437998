import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Button, IconButton, Tooltip } from "@mui/material";

import Header from "../../Component/Home/header";
import ShareIcon from "@mui/icons-material/Share";
import moment from "moment";
import url from "../../constants/url";
import ContestConfirmationPopup from "../Popup/ConstestConfirmatinoPopup";
import { useNavigate } from "react-router-dom";
import { routehelp } from "../config/routehelp";
import ToastPopup from "../Popup/ToastPopup";
import { getInGameNameByIdentifier } from "../../apis";
import WinningBreakup from "../Popup/WinningBreakup";

import { RWebShare } from "react-web-share";
import { formatDate, formatTime, shareLink } from "../Utilities/helper";
import { addTrackData, trackerEventId } from "../../config/tracker";

const ContestStructure = ({ handleRegisterClick, contest, uniqueIgn }) => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isPricePointPoolModel, setPricePointPool] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isIsTournamentConfirmationPopup, setIsTournamentConfirmationPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const totalSlots = contest?.totalPlayers || 0;
  const slotsFilled = contest?.totalJoinedPlayers || 0;
  const percentageFilled = (slotsFilled / totalSlots) * 100;
  const remainingPlayers = contest?.totalPlayers - contest?.totalJoinedPlayers;
  const redFillWidth = contest?.totalJoinedPlayers >= contest?.minimumSlot ? 0 : ((contest?.minimumSlot || 0) / totalSlots) * 100; // Width of the red fill

  const handleClick = (contest) => {
    if (contest?.isJoined) {
      navigate("/contest/details/" + contest?._id);
    }
    // if (handleRegisterClick) {
    //   handleRegisterClick(contest);
    // } else {
    //   setIsTournamentConfirmationPopup(true);
    // }
    if (!uniqueIgn?.trim()) {
      setErrorMsg("Please enter your in game user name first");
      setShowToast(true);
    }
    else {
      setIsTournamentConfirmationPopup(true);
    }
  };

  return (
    <>
      <Box className="tab-panel-box">
        <div className="row col-12 superpower-container">
          <div className="col-6 ">
            <div item className="superpower-box tournament-superpower-title-marquee">
              <div className="superpower-title">{contest?.title?.length > 18 ? <marquee>{contest?.title}</marquee> : contest?.title}</div>
            </div>
          </div>
          <div className="row col-6">
            <div item textAlign="right" className="col-6 p-0">
              <div container spacing={2} className="details-grid slots-info">
                <div item textAlign="right" className="superpower-box superpower-box-1 alignitemcenter">
                  <Button
                    variant="contained"
                    size="small"
                    className="completed-button"
                    disableElevation
                    onClick={() => {
                      navigator.clipboard.writeText(contest?.shortCode);
                    }}>
                    ID:{contest?.shortCode}
                  </Button>
                </div>
              </div>
            </div>
            <div item className="col-6 superpower-box superpower-box-id alignitemcenter">
              <RWebShare
                data = {shareLink(localStorage?.getItem('game'),contest?.gameTypeAry?._id,contest?.gameTypeAry?.name,contest?._id,'contest')}
                // data={{
                //   text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
                //   url: `${url.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem("game")}&gameType=${contest?.gameTypeAry?._id}&gameTypeName=${
                //     contest?.gameTypeAry?.name
                //   }&type=contest`,
                // }}
                sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
                onClick={() => {
                  console.log("shared successfully!");
                  addTrackData(trackerEventId?.Contest_Share_Button_Click,{gameCatalogName:`${contest?.gameAry[0]?.name}`,gameCatalogId:`${contest?.gameAry[0]?._id}`,contestId:`${contest?._id}`});

                }}>
                <Button
                  variant="contained"
                  size="small"
                  className="completed-button completed-share-button"
                  disableElevation
                  endIcon={
                    <svg className="share-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" fill="none">
                      <path d="M15.7601 6.72045L9.98285 0.943246C9.92226 0.882726 9.84508 0.84152 9.76108 0.824833C9.67708 0.808146 9.59002 0.816726 9.5109 0.849489C9.43177 0.882253 9.36413 0.93773 9.31651 1.00891C9.2689 1.0801 9.24344 1.1638 9.24337 1.24944V4.29691C5.3091 4.55616 0.549405 8.26873 0.00490352 13.0104C-0.0127803 13.1614 0.0176339 13.3141 0.0918134 13.4468C0.165993 13.5795 0.280154 13.6854 0.418034 13.7494C0.555914 13.8134 0.710481 13.8323 0.859714 13.8033C1.00895 13.7743 1.14524 13.699 1.24917 13.5881C2.06231 12.7208 4.98268 9.95857 9.24337 9.7802V12.8038C9.24344 12.8895 9.2689 12.9732 9.31651 13.0444C9.36413 13.1155 9.43177 13.171 9.5109 13.2038C9.59002 13.2365 9.67708 13.2451 9.76108 13.2284C9.84508 13.2118 9.92226 13.1705 9.98285 13.11L12.8715 10.2214L15.7601 7.33283C15.8412 7.25159 15.8868 7.14146 15.8868 7.02664C15.8868 6.91182 15.8412 6.80169 15.7601 6.72045ZM10.1099 11.7582V9.33752C10.1099 9.2226 10.0643 9.11239 9.98304 9.03114C9.90178 8.94988 9.79158 8.90423 9.67666 8.90423C7.67414 8.90423 5.72217 9.42779 3.87635 10.4605C2.80051 11.0648 1.80952 11.8091 0.929256 12.6738C1.26867 10.7558 2.38511 8.91867 4.10672 7.45487C5.81099 6.01129 7.89223 5.14905 9.67666 5.14905C9.79158 5.14905 9.90178 5.1034 9.98304 5.02214C10.0643 4.94088 10.1099 4.83067 10.1099 4.71576V2.29583L14.8408 7.02664L10.1099 11.7582Z" />
                    </svg>
                  }>
                  Share
                </Button>
              </RWebShare>
            </div>
          </div>
        </div>

        <div className="details-container">
        <div className="row contest-detail-grid">
      <div className="col-3 details-grid-box">
        <div className="details-label spacing-80">Date</div>
        <div className="details-value spacing-80">
          {formatDate(contest?.date)}
        </div>
      </div>
      <div className="col-3 details-grid-box">
        <div className="details-label spacing-80">Time</div>
        <div className="details-value spacing-80">
          {formatTime(contest?.time)}
        </div>
      </div>
      {contest?.titles?.[0]?.isSelection && contest?.titles?.[0]?.name && contest?.titles?.[0]?.value && (
        <div className="col-3 details-grid-box">
          <div className="details-label spacing-80">{contest?.titles?.[0]?.name}</div>
          <div className="details-value spacing-80 truncate">
            {contest?.titles?.[0]?.value}
          </div>
        </div>
      )}
      {contest?.titles?.[1]?.isSelection && contest?.titles?.[1]?.name && contest?.titles?.[1]?.value && (
        <div className="col-3 details-grid-box">
          <div className="details-label spacing-80">{contest?.titles?.[1]?.name}</div>
          <div className="details-value spacing-80">
            {contest?.titles?.[1]?.value}
          </div>
        </div>
      )}
    </div>
    <div className="row mt-3">
    <div className="col-3 details-grid-box mt-2">
        <div className="details-label">Prize Pool</div>
        <div >
  <span className="icon me-1">
    <img src={url?.image_url + contest?.currency?.[0]?.outCurrency?.img?.default} alt="" className="icon-14" />
  </span>
 {contest?.prizePool}
</div>

      </div>
      <div className="col-9 details-grid-box">
        <div className="row">
          <div className="col-5 details-grid-box mt-2">
          <div className="details-label">{contest?.winningModel == "perKill" ? "Per Kill" : "Winners"}</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="me-1"> 
                     {contest?.winningModel === "perKill" ? <span className="icon me-1">
                  <img src={url?.image_url + contest?.currency?.[0]?.outCurrency?.img?.default} alt="" className="icon-14" />
                </span> :<></>}
              {contest?.winningModel === "perKill" ? contest?.killPoints : contest?.totalWinners}</div>

                    {contest?.winningModel !== "perKill" ? (
                      <div onClick={() => setPricePointPool(true)}>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.164 12.4927L2.828 7.35586L2.828 5.82268L4.63538 5.82268L8.164 9.22287L11.6926 5.82268L13.4973 5.82268L13.5 7.35585L8.164 12.4927Z"
                            fill="#E8F3F5"
                          />
                        </svg>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
          </div>
          <div className="col-7 details-grid-box mt-2">
          <div className="details-label">Join Using</div>
                  <div>
                    {contest?.entryFee > 0 ? (
                      <span className="icon me-2">
                        <img src={url.image_url + contest?.currency?.[0]?.inCurrency?.img?.default} alt="" className="icon-14" />
                      </span>
                    ) : (
                      ""
                    )}
                    {contest?.entryFee > 0 ? contest?.entryFee : "Free"}
                  </div>
          </div>
        </div>
      </div>
      </div>
          <Grid container spacing={2} className="details-grid slots-info">
            <Grid item xs={12} className="details-grid-box">
              <div className="progress-bar-wrapper mt-3">
                <LinearProgress variant="determinate" value={percentageFilled || 0} className="custom-linear-progress" />
                <Tooltip
                  title={`Minimum ${contest?.minimumSlot || 0} Sot Required`}
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#1F3338",
                        border: "1px solid #87A7A6",
                        color: "#86A6A6",
                        fontSize: "0.75rem",
                        filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                        borderRadius: "4px",
                        padding: "8px",
                        width: "162px",
                        height: "50.758px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#1F3338",
                      },
                    },
                  }}
                  classes={{ tooltip: "custom-tooltip" }}>
                  <div
                    className="progress-bar-red-fill"
                    style={{
                      width: `${redFillWidth || 0}%`,
                      left: `${percentageFilled || 0}%`,
                      position: "absolute",
                      height: "100%",
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6} className="details-grid-box mt-1">
              <div className="slots-remaining">{contest?.totalPlayers - contest?.totalJoinedPlayers} slots remaining</div>
            </Grid>
            <Grid item xs={6} textAlign="right" className="details-grid-box mt-1">
              <div className="slots-filled">{contest?.totalJoinedPlayers} slots filled</div>
            </Grid>
          </Grid>

          {contest?.isJoined == false && remainingPlayers > 0 ? (
            <div className="regiter-container" onClick={() => handleClick(contest)}>
              <Grid container spacing={2} className="details-grid slots-info">
                <Grid item xs={6} textAlign="center" className="details-grid-box">
                  <div className="tournamentregbtn">REGISTER</div>
                </Grid>
              </Grid>
            </div>
          ) : contest?.isJoined === false && remainingPlayers === 0 ? (
            <div className="regiter-container">
              <Grid container spacing={2} className="details-grid slots-info">
                <Grid item xs={6} textAlign="center" className="details-grid-box">
                  <div className="tournamentregbtn">FULL</div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="regiter-container-joined" onClick={() => handleClick(contest)}>
              <Grid container spacing={2} className="details-grid slots-info">
                <Grid item xs={6} textAlign="center" className="details-grid-box">
                  <div className="tournamentregbtnjoined">JOINED</div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>

        {/* roomid pass */}
      </Box>
      {isPricePointPoolModel && (
        <WinningBreakup
          isOpen={isPricePointPoolModel}
          title="Winning Breakup"
          onClose={() => setPricePointPool(false)}
          prizePool={contest}
          tournaments={contest}
        />
      )}
      {isIsTournamentConfirmationPopup && (
        <ContestConfirmationPopup
          isOpen={isIsTournamentConfirmationPopup}
          title={contest?.title}
          onClose={() => setIsTournamentConfirmationPopup(false)}
          contest={contest}
          uniqueIgn={uniqueIgn}
          setIsContestConfirmationPopup={setIsTournamentConfirmationPopup}
          setErrorMsg={setErrorMsg}
          setShowToast={setShowToast}
        />
      )}
      {showToast && <ToastPopup message={errorMsg} setShowToast={setShowToast} />}
    </>
  );
};

export default ContestStructure;

import instance from "./config/axios";
import refreshInstance from "./config/refreshAxios";
import url from "./constants/url";

export const gameTypeList = (data) => {
    return instance
    .post(`${url.saas_api_url}web/gameType/gameTypeList`, data)
    .then((res) => {
        return res;
    });
};  

export const login = () => {
    instance.defaults.headers['x-access-token'] = localStorage.getItem('accessToken');
    instance.defaults.headers['user-jwt-token'] = localStorage.getItem('accessToken');

    return instance
    .get(`${url.indigg_api_url}v2/profiles/user`)
    .then((res) => {
        return res;
    });
};

export const refreshTokenApi = () => {
    refreshInstance.defaults.headers['x-access-token'] = localStorage.getItem('refreshToken');
    refreshInstance.defaults.headers['user-jwt-token'] = localStorage.getItem('refreshToken');

    return refreshInstance
    .get(`${url.indigg_api_url}v2/profiles/refreshToken`)
    .then((res) => {
        return res;
    });
};

export const tournamentListApi = (data) => {
    return instance
    .post(`${url.saas_api_url}web/tournaments/list`, data)
    .then((res) => {
        return res;
    });
}; 

export const tournamentRulesApi = (data) => {
    return instance
    .post(`${url.saas_api_url}web/tournaments/getRules/${data}`)
    .then((res) => {
        return res;
    });
};

export const tournamentPricePointPoolApi = (data) => {
    return instance
    .post(`${url.saas_api_url}web/tournaments/tournamentPricePointPool/${data}`)
    .then((res) => {
        return res;
    });
};

export const tournamentByCode = (data) => {
    return instance
    .post(`${url.saas_api_url}web/tournaments/eventByCode`, data)
    .then((res) => {
        return res;
    });
};

//List Contest
export const contestListApi = (data) => {
    return instance
    .post(`${url.saas_api_url}web/contest/list`, data)
    .then((res) => {
        return res;
    });
};
//join contest
export const joinContestApi = (data) => {
    return instance
    .post(`${url.saas_api_url}indigg/joinUserContest/join`, data)
    .then((res) => {
        return res;
    }) ;
};
//get contest by code
export const getContestById = (data) => {
    return instance
    .post(`${url.saas_api_url}web/contest/getByCode/${data.code}`, data)
    .then((res) => {
        return res;
    }) ;
};
//get contest info
export const contestInfoApi = (id,data) => {
    return instance
    .post(`${url.saas_api_url}web/contest/getContestInfo/${id}`,data)
    .then((res) => {
        return res;
    });
};
//get contest players
export const contestPlayersApi = (id,data) => {
    return instance
    .post(`${url.saas_api_url}web/contest/playersInfo/${id}`,data)
    .then((res) => {
        return res;
    });
};
//get contest info
export const contestRulesApi = (id,data) => {
    return instance
    .post(`${url.saas_api_url}web/contest/rules/${id}`,data)
    .then((res) => {
        return res;
    });
};

export const tournamentRoundsById = (data) => {
    return instance
    .post(`${url.saas_api_url}web/tournaments/tournamentRoundsById/${data}`)
    .then((res) => {
        return res;
    });
};


export const tournamentMatchByRoundId = (id, data) => {
    return instance
    .post(`${url.saas_api_url}web/tournaments/tounamentMatchesById/${id}`, data)
    .then((res) => {
        return res;
    });
};


export const tournamentById = (data) => {
    return instance
    .post(`${url.saas_api_url}web/tournaments/getTournamentInfo/${data}`)
    .then((res) => {
        return res;
    });
};

export const indiggTournamentJoin = (data) => {
    return instance
    .post(`${url.saas_api_url}indigg/joinUserTournament/join`, data)
    .then((res) => {
        return res;
    });
};

export const getInGameNameByIdentifier = (data) => {
    return instance
        .post(`${url.indigg_api_url}v2/profiles/userNameByIdentifier`, data)
        .then((res) => {
            return res;
        }).catch((error) => {
            return error;
        });
};

export const indiggTournamentRoundMatch = (data, id) => {
    return instance
    .post(
        `${url.saas_api_url}web/tournaments/getTournamentRounds/${id}`,
        data
    )
    .then((res) => {
        return res;
    });
};

export const indiggTournamentDetailPlayers = (data, id) => {
    return instance
    .post(
        `${url.saas_api_url}web/tournaments/getEventLeaderBoardById/${id}`,
        data
    )
    .then((res) => {
        return res;
    });
};

//get my contest
export const getMyContestApi = (data) => {
    return instance
    .post(`${url.saas_api_url}web/contest/myContestList`, data)
    .then((res) => {
        console.log(res)
        return res;
    }) ;
};
//get my tournament
export const getMyTournamentApi = (data) => {
    return instance
    .post(`${url.saas_api_url}web/tournaments/myTournament`, data)
    .then((res) => {
        console.log(res)
        return res;
    }) ;
};

export const createTeam = (data) => {
    return instance
        .post(`${url.indigg_api_url}v2/team/add`, data)
        .then((res) => {
            return res;
        }).catch((error) => {
            return error;
        });
};

export const getTeam = (data) => {
    return instance
        .post(`${url.indigg_api_url}v2/team/get`, data)
        .then((res) => {
            return res;
        }).catch((error) => {
            return error;
        });
};

export const updateTeam = (data, id) => {
    return instance
        .post(`${url.indigg_api_url}v2/team/update/${id}`, data)
        .then((res) => {
            return res;
        }).catch((error) => {
            return error;
        });
};

export const updateInGameNameByIdentifier = (data) => {
    return instance
        .post(`${url.indigg_api_url}v2/profiles/userNameByIdentifier/update`, data)
        .then((res) => {
            console.log(res)
            return res;
        }).catch((error) => {
            return error;
        });
};

export const onCreateTournament = (data) => {
    return instance
    .post(`${url.saas_api_url}indigg/joinUserTournament/join`, data)
    .then((res) => {
        return res;
    });
};

export const indiggTracker = (eventId,data) => {
    refreshInstance.defaults.headers['x-access-token'] = localStorage.getItem('refreshToken');
    refreshInstance.defaults.headers['user-jwt-token'] = localStorage.getItem('refreshToken');
    return refreshInstance
    .post(`${url.trackerUrl(eventId)}`,data)
    .then((res) => {
        return res;
    });
  };
import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Button, Divider, Tabs, Tab, capitalize } from "@mui/material";
import { ReactComponent as Iconsvg } from "../../../../src/assets/images/icon.svg";
import { contestInfoApi, contestPlayersApi, contestRulesApi } from "../../../apis";
import Loader from "../../loader";

const ContestPlayers = ({ tab, contestId, contestDetail }) => {
  const [contestPlayers, setContestPlayers] = useState(null);
  const [contestPlayersList, setContestPlayersList] = useState([]);
   
   //pagination
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPage, setTotalPage] = useState(-1);
   const [recordPerPage, setRecordsPerPage] = useState(10);
   const [lazyLoading, setLazyLoading] = useState(false);
   const [hasMore, setHasMore] = useState(true);
   useEffect(() => {
     if (currentPage) {
       if (!lazyLoading){
        getPlayerInfo(currentPage);
       }
     }
   }, [currentPage]);
   const handleScroll = () => {
     const container = document.getElementById('scroll-container');
     if (!container) return;
     const scrollTop = container.scrollTop;
     const scrollHeight = container.scrollHeight;
     const clientHeight = container.clientHeight;
     if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !lazyLoading) {
       setCurrentPage(currentPage + 1)
     }
   };
   useEffect(() => {
     const container = document.getElementById('scroll-container');
     if (container) {
       container.addEventListener('scroll', handleScroll);
       return () => {
         container.removeEventListener('scroll', handleScroll);
       };
     }
   }, [hasMore, lazyLoading]);

  async function getPlayerInfo(page) {
    setLazyLoading(true);
    if (page === 1) {
        setContestPlayersList([]);
    }
    try {
      var payload = {
        pagination: {
          pageNo: page,
          recordPerPage: recordPerPage, //recordPerPage
          sortBy: "rank",
          sortDirection: "asc",
        },
        search: {
          user: JSON?.parse(localStorage?.getItem("profile"))?.user_id,
        },
      };

      let response = await contestPlayersApi(contestId, payload);
      if (response && response?.success) {
        setCurrentPage(page);
        setTotalPage(response?.totalPages || 0);
        if (response?.totalPages === page) {
          setHasMore(false);
        }

        setLazyLoading(false);
        setContestPlayers(response);
        setContestPlayersList([...contestPlayersList, ...response?.data]);
      } else {
        setLazyLoading(false);
      }
    } catch (error) {
      setLazyLoading(false);
      //console.log("players error",error);
    }
  }

  return (
    (lazyLoading && currentPage == 1) ? <Loader /> :
    <div className="contestdetailscroll" >
      <Box className="tab-panel-box">
        <div className="body-scrolltable" id="scroll-container" style={{   overflowY: 'scroll' }}>
          <div className="superpower-container borderradius" >
            <table className="superpower-wapper" style={{ width: "100%", textAlign: "center" }}>
              <thead style={{ background: "#8CFF05" }}>
                <tr>
                  <th
                    className="tableheading"
                    style={{
                      color: "white",
                      padding: "16px",
                      textAlign: "left",
                      width: contestDetail?.status === "completed" ? "25%" : "33.33%", // Adjust width based on Winnings column visibility
                    }}>
                    Username
                  </th>
                  {contestDetail?.status === "completed" && (
                    <th
                      className="tableheading"
                      style={{
                        color: "white",
                        padding: "16px",
                        textAlign: "center",
                        // width: "25%", // Fixed width for Winnings column
                      }}>
                      Winnings
                    </th>
                  )}
                  <th
                    className="tableheading"
                    style={{
                      color: "white",
                      padding: "16px",
                      textAlign: "center", // Align Kills left if Winnings is shown, otherwise center
                      // width: "25%", // Fixed width for Kills column
                    }}>
                    Kills
                  </th>
                  <th
                    className="tableheading"
                    style={{
                      color: "white",
                      padding: "16px",
                      textAlign: "center", // Align Ranks center if Winnings is shown, otherwise left
                      // width: "25%", // Fixed width for Ranks column
                    }}>
                    Ranks
                  </th>
                </tr>
              </thead>
              <tbody>
                {contestPlayers?.captainData ? (
                  <tr
                    key={-1}
                    style={{
                      borderBottom: "1px solid var(--core-neutral-dark, #415F66)",
                      padding: "13.5px 16px",
                      backgroundColor: "#415F66",
                    }}>
                    <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "left" }}>
                      {contestPlayers?.captainData?.userName}
                    </td>
                    {contestDetail?.status === "completed" && (
                      <td
                        className="tablevalues"
                        style={{
                          padding: "13.5px 16px",
                          textAlign: "center",
                        }}>
                        <span>
                          {" "}
                          <Iconsvg />
                        </span>{" "}
                        {contestPlayers?.captainData?.totalAmount === 0 ? "-" : contestPlayers?.captainData?.totalAmount}
                      </td>
                    )}
                    <td
                      className="tablevalues"
                      style={{
                        padding: "13.5px 16px",
                        textAlign: "center", // Dynamically set alignment based on Winnings column
                      }}>
                      {contestPlayers?.captainData?.kills === 0 ? "-" : contestPlayers?.captainData?.kills}
                    </td>
                    <td
                      className="tablevalues"
                      style={{
                        padding: "13.5px 16px",
                        textAlign: "center", // Dynamically set alignment based on Winnings column
                      }}>
                      {contestPlayers?.captainData?.rank === 0 ? "-" : contestPlayers?.captainData?.rank}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {contestPlayersList?.length > 0
                  ? contestPlayersList?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid var(--core-neutral-dark, #415F66)",
                          padding: "13.5px 16px",
                          backgroundColor: "inherit",
                        }}>
                        <td className="tablevalues" style={{ padding: "13.5px 16px", textAlign: "left" }}>
                          {item.userName}
                        </td>
                        {contestDetail?.status === "completed" && (
                          <td
                            className="tablevalues"
                            style={{
                              padding: "13.5px 16px",
                              textAlign: "center",
                            }}>
                            -
                          </td>
                        )}
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}>
                          {item.kills === 0 ? "-" : item.kills}
                        </td>
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}>
                          {item?.rank === 0 ? "-" : item?.rank}
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ContestPlayers;

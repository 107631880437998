import React from "react";
import Header from "../Home/header";
import { useNavigate } from "react-router-dom";
import url from "../../constants/url";
export const PageNotFound = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
      };
    return (
        // <Header title={localStorage.getItem("selectedGameMode")} onBackClick={handleBackClick} background={true}>
        <div className="radial page-not-found-container">
            <div className="error-box">
                <h1 className="error-title">404 - Page Not Found</h1>
                <p className="error-message">
                    The page doesn't exist. Go back to the <a href={url?.klash_redirect_url} className="homepage-link">Homepage</a>.
                </p>
            </div>
        </div>
        // </Header>
    );
};
export const NotDataFound = ({ msg }) => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
      };
    return (
        // <Header title={localStorage.getItem("selectedGameMode")} onBackClick={handleBackClick} background={true}>
        <div className="page-not-found-container">
            <div className="w-100 ">
                <h4 className="error-title nodata">{msg || 'No games found'}</h4>
            </div>
        </div>
        // </Header>
    );
};

import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
    Box,
    Grid,
    LinearProgress,
    Button,
    Divider,
    Tabs,
    Tab,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Tournaments from "../../../src/assets/images/Tournaments.png";
import url from "../../constants/url";
import RulesPopup from "../Popup/Rules";
import WinningBreakup from "../Popup/WinningBreakup";
import TournamentRoundPopup from "../Popup/TournamentRoundPopup";
import TournamentConfirmationPopup from "../Popup/TournamentConfirmationPopup";
import ToastPopup from "../Popup/ToastPopup";
import { useNavigate } from "react-router";
import Loader from "../loader";

import { tournamentRulesApi, tournamentPricePointPoolApi, tournamentRoundsById } from "../../apis";

import { RWebShare } from "react-web-share";
import { formatDate, shareLink } from "../Utilities/helper";
import { addTrackData, trackerEventId } from "../../config/tracker";

const TournamentStructure = ({ tournaments }) => {
    const [isRulesPopup, setIsRulesPopup] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isIsTournamentRoundPopup, setIsTournamentRoundPopup] = useState(false);
    const [isIsTournamentConfirmationPopup, setIsTournamentConfirmationPopup] = useState(false);
    const [rules, setRules] = useState('');
    const [prizePool, setPrizePool] = useState('');
    const [rounds, setRounds] = useState([]);
    const [firstRoundId, setFirstRoundId] = useState('');
    const [uniqueIgn, setUniqueIgn] = useState('');
    const [selectedMatchDate, setSelectedMatchDate] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate();
    const [teamRegistration, setTeamRegistration] = useState(false);
    const [isLoading, setIsLoader] = useState(false);

    const totalSlots = tournaments?.totalPlayers || 0;
    const slotsFilled = tournaments?.totalJoinedPlayers || 0;
    const percentageFilled = (slotsFilled / totalSlots) * 100;
    const redFillStart = percentageFilled; // Start red fill after the percentageFilled

    const redFillWidth = tournaments?.totalJoinedPlayers >= tournaments?.minimumSlot ? 0 : ((tournaments?.minimumSlot || 0) / totalSlots) * 100; // Width of the red fill
    const remainingPlayers = tournaments?.totalPlayers - tournaments?.totalJoinedPlayers;

    async function fetchRulesData() {
        try {
            setIsLoader(true);
            let response = await tournamentRulesApi(tournaments?._id);
            if (response?.success) {
                setIsLoader(false);
                setRules(response?.item?.rules);
            }
            else {
                setIsLoader(false);
                setRules('');
            }
            setIsRulesPopup(true)
        } catch (error) {
            setIsLoader(false);
            setRules('');
            console.log("getTournament error");
        }
    }

    async function fetchPricePointPoolData() {
        setIsLoader(true);
        try {
            let response = await tournamentPricePointPoolApi(tournaments?._id);
            if (response?.success) {
                setIsLoader(false);
                setPrizePool(response?.data);
            }
            else {
                setIsLoader(false);
                setPrizePool('');
            }
            setIsPopupOpen(true)
        } catch (error) {
            setIsLoader(false);
            setPrizePool('');
            console.log("getTournament error");
        }
    }

    const getjoin = async () => {
        setIsLoader(true);
        try {
            let response = await tournamentRoundsById(tournaments?._id);
            if (response?.success) {
                setIsLoader(false);
                setRounds(response);
                setIsTournamentRoundPopup(true)
            }
            else {
                setIsLoader(false);
                setRounds([]);
            }
        } catch (error) {
            setIsLoader(false);
            setRounds([]);
            console.log("getTournament error");
        }
    }

    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Box className="tab-panel-box">
                <div className="row col-12 superpower-container">
                    <div className="col-6 ">
                    <div item className="superpower-box tournament-superpower-title-marquee">
                        <div className="superpower-title">{tournaments?.title?.length > 18 ? <marquee>{tournaments?.title}</marquee> : tournaments?.title}</div>
                    </div> 

                    </div>
                    <div className="row col-6">
                    <div item textAlign="right" className="col-6 p-0">
                        <div container spacing={2} className="details-grid slots-info">
                    
                        <div
                            item
                            textAlign="right"
                            className="superpower-box superpower-box-1 alignitemcenter"
                        >
                            <Button
                            variant="contained"
                            size="small"
                            className="view-rules"
                            disableElevation
                            onClick={() => fetchRulesData()}
                            >
                            View Rules
                            </Button>
                        </div>

                        
                        </div>

                    </div>
                    <div
                            item
                           
                            className="col-6 superpower-box superpower-box-id alignitemcenter"
                        >
                            <RWebShare
                                data={shareLink(localStorage?.getItem('game'),null,null,tournaments?._id,'tournament')}
                                sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
                                onClick={() => {
                                console.log("shared successfully!")
                                addTrackData(trackerEventId?.Tournament_Share_Button_Click,{gameCatalogName:`${tournaments?.gameAry?.name}`,gameCatalogId:`${tournaments?.gameAry?._id}`,tournamentId:`${tournaments?._id}`});
                                }}
                            >
                                <Button
                                variant="contained"
                                size="small"
                                className="completed-button completed-share-button"
                                disableElevation
                                endIcon={
                                    <svg
                                    className="share-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 14"
                                    fill="none"
                                    >
                                    <path d="M15.7601 6.72045L9.98285 0.943246C9.92226 0.882726 9.84508 0.84152 9.76108 0.824833C9.67708 0.808146 9.59002 0.816726 9.5109 0.849489C9.43177 0.882253 9.36413 0.93773 9.31651 1.00891C9.2689 1.0801 9.24344 1.1638 9.24337 1.24944V4.29691C5.3091 4.55616 0.549405 8.26873 0.00490352 13.0104C-0.0127803 13.1614 0.0176339 13.3141 0.0918134 13.4468C0.165993 13.5795 0.280154 13.6854 0.418034 13.7494C0.555914 13.8134 0.710481 13.8323 0.859714 13.8033C1.00895 13.7743 1.14524 13.699 1.24917 13.5881C2.06231 12.7208 4.98268 9.95857 9.24337 9.7802V12.8038C9.24344 12.8895 9.2689 12.9732 9.31651 13.0444C9.36413 13.1155 9.43177 13.171 9.5109 13.2038C9.59002 13.2365 9.67708 13.2451 9.76108 13.2284C9.84508 13.2118 9.92226 13.1705 9.98285 13.11L12.8715 10.2214L15.7601 7.33283C15.8412 7.25159 15.8868 7.14146 15.8868 7.02664C15.8868 6.91182 15.8412 6.80169 15.7601 6.72045ZM10.1099 11.7582V9.33752C10.1099 9.2226 10.0643 9.11239 9.98304 9.03114C9.90178 8.94988 9.79158 8.90423 9.67666 8.90423C7.67414 8.90423 5.72217 9.42779 3.87635 10.4605C2.80051 11.0648 1.80952 11.8091 0.929256 12.6738C1.26867 10.7558 2.38511 8.91867 4.10672 7.45487C5.81099 6.01129 7.89223 5.14905 9.67666 5.14905C9.79158 5.14905 9.90178 5.1034 9.98304 5.02214C10.0643 4.94088 10.1099 4.83067 10.1099 4.71576V2.29583L14.8408 7.02664L10.1099 11.7582Z" />
                                    </svg>
                                }
                                >
                                Share
                                </Button>
                            </RWebShare>
                        </div>
                    </div>
                   
                </div>

                <div className="details-container">
                    <Grid container spacing={2} className="details-grid">
                        <Grid item xs={4} className="details-grid paddingright">
                            <div className="image-container">
                            {tournaments?.featuredImage?.default ? (
                                <img src={url.image_url + tournaments?.featuredImage?.default} alt="" className="game-mode-img" />
                            ) : (
                                <img src={Tournaments} alt="" className="game-mode-img" />
                            )}
                            <span className="image-text">{tournaments?.gameTypeAry?.name}</span>
                            </div>
                        </Grid>

                        <Grid item xs={8} className="details-grid">
                            <Grid container spacing={2} className="details-grid marginBottom">
                                <Grid item xs={5} className="details-grid-box w-100">
                                    <div className="details-label">Date</div>
                                    <div className="details-value">{formatDate(tournaments?.startDate)}</div>
                                </Grid>
                                <Grid item xs={4} className="details-grid-box">
                                    <div className="details-label">Rounds</div>
                                    <div className="details-value">{tournaments?.roundsCount}</div>
                                </Grid>
                                <Grid item xs={3} className="details-grid-box">
                                    <div className="details-label">ID</div>
                                    <div className="details-value" onClick={() => {
                                        navigator.clipboard.writeText(tournaments?.shortCode);
                                    }}>{tournaments?.shortCode}</div>
                                </Grid>
                            </Grid>
                            <Grid container className="details-grid slots-info">
                                {tournaments?.titles?.[0]?.isSelection && tournaments?.titles?.[0]?.name && tournaments?.titles?.[0]?.value &&
                                    <Grid item xs={5} className="details-grid-box">
                                        <div className="details-label">{tournaments?.titles?.[0]?.name}</div>
                                        <div className="details-value">{tournaments?.titles?.[0]?.value}</div>
                                    </Grid>
                                }
                                {tournaments?.titles?.[1]?.isSelection && tournaments?.titles?.[1]?.name && tournaments?.titles?.[1]?.value &&
                                    <Grid item xs={7} className="details-grid-box">
                                        <div className="details-label">{tournaments?.titles?.[1]?.name}</div>
                                        <div className="details-value">{tournaments?.titles?.[1]?.value}</div>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className="details-grid">
                    <Grid item xs={4} className="details-grid-box">
                        <div className="details-label">Prize Pool</div>
                        <div>
                        {
                            tournaments?.prizePoolType === 'amount' &&
                            <span className="icon me-1">
                                <img
                                src={
                                    url?.image_url +
                                    tournaments?.currency?.[0]?.outCurrency?.img?.default
                                }
                                alt=""
                                />
                            </span>
                        }
                        {tournaments?.prizePoolType === 'amount' ? tournaments?.prizePool : tournaments?.rewardDisplayText}
                        </div>
                    </Grid>
                    <Grid item xs={8} className="details-grid-box">
                        <Grid container spacing={2} className="details-grid">
                        <Grid item xs={5} className="details-grid-box">
                            <div className="details-label">Winners</div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="me-1">{tournaments?.totalWinners}</div>
                                <div onClick={() => fetchPricePointPoolData()}>
                                    <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M8.164 12.4927L2.828 7.35586L2.828 5.82268L4.63538 5.82268L8.164 9.22287L11.6926 5.82268L13.4973 5.82268L13.5 7.35585L8.164 12.4927Z"
                                        fill="#E8F3F5"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} className="details-grid-box">
                            <div className="details-label">Join Using</div>
                            <div>
                            {tournaments?.entryFee > 0 ? (
                                <span className="icon me-2">
                                    <img
                                    src={
                                        url.image_url +
                                        tournaments?.currency?.[0]?.inCurrency?.img?.default
                                    }
                                    alt=""
                                    />
                                </span>
                            ) : (
                            ""
                            )}
                            {tournaments?.entryFee > 0 ? tournaments?.entryFee : "Free"}
                            </div>
                        </Grid>
                        </Grid>
                    </Grid>
                    </Grid>

                    <Grid container spacing={2} className="details-grid slots-info">
                    <div className="progress-bar-wrapper mt-2">
                        <LinearProgress
                        variant="determinate"
                        value={percentageFilled}
                        className="custom-linear-progress"
                        />
                        <Tooltip
                        title={`Minimum ${tournaments?.minimumSlot || 0} Slot Required`}
                        arrow
                        placement="top"
                        PopperProps={{
                            sx: {
                            "& .MuiTooltip-tooltip": {
                                backgroundColor: "#1F3338",
                                border: "1px solid #87A7A6",
                                color: "#86A6A6", // Text color
                                fontSize: "0.75rem",
                                filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                                borderRadius: "4px",
                                padding: "8px",
                                width: "162px",
                                height: "50.758px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            },
                            "& .MuiTooltip-arrow": {
                                color: "#1F3338",
                            },
                            },
                        }}
                        classes={{ tooltip: "custom-tooltip" }}
                        >
                        <div
                            className="progress-bar-red-fill"
                            style={{
                            width: `${redFillWidth}%`,
                            left: `${percentageFilled}%`,
                            position: "absolute",
                            height: "100%",
                            }}
                        />
                        </Tooltip>
                    </div>
                    <Grid item xs={6} className="details-grid-box" style={{ marginTop: "3px"}}>
                        <div className="slots-remaining">{tournaments?.totalPlayers - tournaments?.totalJoinedPlayers} slots remaining</div>
                    </Grid>
                    <Grid item xs={6} textAlign="right" className="details-grid-box" style={{ marginTop: "3px"}}>
                        <div className="slots-filled">{tournaments?.totalJoinedPlayers} slots filled</div>
                    </Grid>
                    </Grid>

                    <div className={`${tournaments?.isJoined ? "regiter-container-joined" : "regiter-container"}`}>
                    <Grid container spacing={2} className="details-grid slots-info">
                        {(tournaments?.isJoined == false && remainingPlayers > 0) ? ( 
                            <Grid item xs={6} textAlign="center" className="details-grid-box">
                                <div className="tournamentregbtn" onClick={() => getjoin()}>REGISTER</div>
                            </Grid>
                        ) : tournaments?.isJoined == false && remainingPlayers == 0 ? (
                            <Grid item xs={6} textAlign="center" className="details-grid-box">
                                <div className="tournamentregbtn">FULL</div>
                            </Grid>
                        ): (
                            <Grid item xs={6} textAlign="center" className="details-grid-box">
                                <div className="tournamentregbtnjoined" onClick={() => navigate("/tournament/details/" + tournaments?._id)}>JOINED</div>
                            </Grid>
                        )}
                        {/* <Grid
                            item
                            xs={6}
                            textAlign="center"
                            className="details-grid-box details-grid-box-1"
                        >
                            <div className="password-label details-label">Password</div>
                            <div className="password-value">#KcEN</div>
                        </Grid> */}
                    </Grid>
                    </div>
                </div>

                {/* roomid pass */}
            </Box>

            {isRulesPopup &&
                <RulesPopup
                    isOpen={isRulesPopup}
                    title="Rules"
                    onClose={() => setIsRulesPopup(false)}
                    rules={rules}
                />
            }

            {isPopupOpen &&
                <WinningBreakup
                    isOpen={isPopupOpen}
                    title="Winning Breakup"
                    onClose={() => setIsPopupOpen(false)}
                    prizePool={prizePool}
                    tournaments={tournaments}
                />
            }

            {isIsTournamentRoundPopup &&
                <TournamentRoundPopup
                    isOpen={isIsTournamentRoundPopup}
                    title={tournaments?.title}
                    onClose={() => setIsTournamentRoundPopup(false)}
                    tournaments={tournaments}
                    rounds={rounds}
                    firstRoundId={firstRoundId}
                    setFirstRoundId={setFirstRoundId}
                    uniqueIgn={uniqueIgn}
                    setUniqueIgn={setUniqueIgn}
                    setIsTournamentRoundPopup={setIsTournamentRoundPopup}
                    setIsTournamentConfirmationPopup={setIsTournamentConfirmationPopup}
                    setSelectedMatchDate={setSelectedMatchDate}
                    setErrorMsg={setErrorMsg}
                    setShowToast={setShowToast}
                />
            }

            {isIsTournamentConfirmationPopup &&
                <TournamentConfirmationPopup
                    isOpen={isIsTournamentConfirmationPopup}
                    title={tournaments?.title}
                    onClose={() => setIsTournamentConfirmationPopup(false)}
                    tournaments={tournaments}
                    selectedMatchDate={selectedMatchDate}
                    firstRoundId={firstRoundId}
                    uniqueIgn={uniqueIgn}
                    setIsTournamentConfirmationPopup={setIsTournamentConfirmationPopup}
                    setErrorMsg={setErrorMsg}
                    setShowToast={setShowToast}
                />
            }

            {showToast && (
                <ToastPopup message ={errorMsg}  setShowToast= {setShowToast} />
            )}
        </>
    )
}

export default TournamentStructure;
import React from "react";
import Popup from "./basepopup";
const RulesPopup = ({ isOpen, title, onClose, rules }) => {
  return (
    <Popup isOpen={isOpen} title={title} onClose={onClose}>
      <>
        <div className="rules-list" dangerouslySetInnerHTML={{ __html: rules }} />
      </>
    </Popup>
  );
};

export default RulesPopup;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyTournamentStructure from "../widgets/myTournamentStructure";
import { getMyTournamentApi } from "../../../apis";
import { NotDataFound } from "../Pagenotfound";

const TournamentTab = ({ tab, setLoader }) => {
  const [tournamentList, setTournamentList] = useState([]);
  const navigate = useNavigate();
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(-1);
  const [recordPerPage, setRecordsPerPage] = useState(5);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    if (currentPage) {
      if (!lazyLoading) {
        getMyTournament(currentPage);
      }
    }
  }, [currentPage]);
  const handleScroll = () => {
    const container = document.getElementById("scroll-container");
    if (!container) return;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !lazyLoading) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    const container = document.getElementById("scroll-container");
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, lazyLoading]);
  async function getMyTournament(page) {
    setLazyLoading(true);
    if (page === 1) {
      setTournamentList([]);
      setLoader(true);
    }
    try {
      let data = {
        game: localStorage?.getItem("game"),
        skip:  ((currentPage-1)*recordPerPage),
        limit: recordPerPage,
        sortBy: "startDate",
        sort: "desc",
        user: JSON?.parse(localStorage?.getItem("profile"))?.user_id,
      };
      let response = await getMyTournamentApi(data);
      if (page === 1) {
        setLoader(false);
      }
      if (response && response?.success) {
        setCurrentPage(page);
        var totalPage = Math.ceil(response?.count / recordPerPage);
        setTotalPage(totalPage|| 0);
        if (totalPage === page) {
            setHasMore(false);
          }
        setLazyLoading(false);
        setTournamentList([...tournamentList, ...response.list]);

      } else {
        setLazyLoading(false);
      }
    } catch (error) {
      if (page === 1) {
        setLoader(false);
      }
      setLazyLoading(false);
      console.log("getTournament error", error);
    }
  }

  const handleTournamentClick = (tournaments) => {
    console.log(tournaments);
    navigate("/tournament/details/" + tournaments?._id);
  };

  return (
    <div id="scroll-container" style={{ height: "100vh", overflowY: "scroll" }}  className="tournament-body-scrolltable">
      {tournamentList && tournamentList.length>0 ? (tournamentList?.map((tournaments, ind) => {
        return <MyTournamentStructure tournaments={tournaments} handleTournamentClick={handleTournamentClick} />;
      })): <NotDataFound/>}
    </div>
  );
};
export default TournamentTab;

import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Button, capitalize } from "@mui/material";
import { ReactComponent as Clipcopy } from "../../../../src/assets/images/clipcopy.svg";

import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import url from "../../../constants/url";
import WinningBreakup from "../../Popup/WinningBreakup";

import Loader from "../../loader";
import { formatDate, formatTime } from "../../Utilities/helper";

const MyContestStructure = ({ handleContestClick, contest }) => {
  const [isPricePointPoolModel, setPricePointPool] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoader] = useState(false);

  const totalSlots = contest?.contestData.totalPlayers;
  const slotsFilled = contest?.contestData.totalJoinedPlayers;
  const percentageFilled = (slotsFilled / totalSlots) * 100;
  const redFillWidth = contest?.contestData.totalJoinedPlayers >= contest?.contestData?.minimumSlot ? 0 : ((contest?.contestData?.minimumSlot || 0) / totalSlots) * 100;

  //Handle Card Click this way due to event propogation issue
  const [clicked, setClicked] = useState(false);
  const handleContestClickPoint = () => {
    setClicked(true)  
    setSelectedItem(contest?.contestData);
                          setPricePointPool(true);
  };
  const handleContest = () => {
    if (!clicked) {
      handleContestClick(contest?.contestData)
    }
    setClicked(false)  
  };
  return (
    <>
  
      <Box className="tab-panel-box" onClick={()=>handleContest()}>
       
      <div className="superpower-container">
      <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  className="superpower-wapper"
                >
                  <div item className="col-8 superpower-box">
                    <div className="superpower-title">{contest?.contestData?.title?.length > 30 ? <marquee>{contest?.contestData?.title}</marquee> : contest?.contestData?.title}</div>
                  </div>

                  <div
                    item
                   
                    className="col-4 superpower-box superpower-box-1 alignitemcenter font-14"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      className="completed-button"
                      disableElevation
                      disableTouchRipple
                      disableFocusRipple
                      disabled
                    >
                      {capitalize(contest?.contestData?.status)}
                    </Button>
                  </div>
                </Grid>
                  </div>
                  

  {/* Details Section */}
  <div className="details-container">
  <div className="row contest-detail-grid">
      <div className="col-3 details-grid-box">
        <div className="details-label spacing-80">Date</div>
        <div className="details-value spacing-80">
          {formatDate(contest?.contestData?.date)}
        </div>
      </div>
      <div className="col-3 details-grid-box">
        <div className="details-label spacing-80">Time</div>
        <div className="details-value spacing-80">
          {formatTime(contest?.contestData?.time)}
        </div>
      </div>
      {contest?.contestData?.titles?.[0]?.isSelection && contest?.contestData?.titles?.[0]?.name && contest?.contestData?.titles?.[0]?.value && (
        <div className="col-3 details-grid-box">
          <div className="details-label spacing-80">{contest?.contestData?.titles?.[0]?.name}</div>
          <div className="details-value spacing-80 truncate">
            {contest?.contestData?.titles?.[0]?.value}
          </div>
        </div>
      )}
      {contest?.contestData?.titles?.[1]?.isSelection && contest?.contestData?.titles?.[1]?.name && contest?.contestData?.titles?.[1]?.value && (
        <div className="col-3 details-grid-box">
          <div className="details-label spacing-80">{contest?.contestData?.titles?.[1]?.name}</div>
          <div className="details-value spacing-80">
            {contest?.contestData?.titles?.[1]?.value}
          </div>
        </div>
      )}
    </div>

    <div className="row mt-3">
      <div className="col-3 details-grid-box mt-2">
        <div className="details-label">Prize Pool</div>
        <div className="d-flex align-items-center">
  <span className="icon me-1">
    <img src={url?.image_url + contest?.contestData?.currency?.[0]?.outCurrency?.img?.default} alt="" className="icon-14" />
  </span>
 {contest?.contestData?.prizePool}
</div>

      </div>
      <div className="col-9 details-grid-box">
        <div className="row">
          <div className="col-5 details-grid-box mt-2">
            <div className="details-label">{contest?.contestData?.winningModel == "perKill" ? "Per Kill" : "Winners"}</div>
            <div className="d-flex align-items-center">
              <div className="me-1">
                {contest?.contestData?.winningModel === "perKill" ? (
                  <span className="icon me-1">
                    <img src={url?.image_url + contest?.contestData?.currency?.[0]?.outCurrency?.img?.default} alt="" className="icon-14" />
                  </span>
                ) : null}
                {contest?.contestData?.winningModel == "perKill" ? contest?.contestData?.killPoints : contest?.contestData?.totalWinners}
              </div>
              {contest?.contestData?.winningModel != "perKill" && (
                <div onClick={(e) => { e.stopPropagation(); handleContestClickPoint(); }}>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.164 12.4927L2.828 7.35586L2.828 5.82268L4.63538 5.82268L8.164 9.22287L11.6926 5.82268L13.4973 5.82268L13.5 7.35585L8.164 12.4927Z" fill="#E8F3F5"/>
                  </svg>
                </div>
              )}
            </div>
          </div>
          <div className="col-7 details-grid-box mt-2">
            <div className="details-label">Join Using</div>
            <div>
              {contest?.contestData?.entryFee > 0 ? (
                <span className="icon me-2">
                  <img src={url.image_url + contest?.contestData?.currency?.[0]?.inCurrency?.img?.default} alt="" className="icon-14" />
                </span>
              ) : null}
              {contest?.contestData?.entryFee > 0 ? contest?.contestData?.entryFee : "Free"}
            </div>
          </div>
        </div>
      </div>
  

      <Grid container spacing={2} className="details-grid slots-info">
            <Grid item xs={12} className="details-grid-box">
              <div className="progress-bar-wrapper mt-3">
                <LinearProgress
                  variant="determinate"
                  value={percentageFilled || 0}
                  className="custom-linear-progress"
                />
                <Tooltip
                  title={`Minimum ${contest?.contestData?.minimumSlot || 0} Slot Required`}
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#1F3338",
                        border: "1px solid #87A7A6",
                        color: "#86A6A6", // Text color
                        fontSize: "0.75rem",
                        filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                        borderRadius: "4px",
                        padding: "8px",
                        width: "162px",
                        height: "50.758px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#1F3338",
                      },
                    },
                  }}
                  classes={{ tooltip: "custom-tooltip" }}>
                  <div
                    className="progress-bar-red-fill"
                    style={{
                      width: `${redFillWidth || 0}%`,
                      left: `${percentageFilled || 0}%`,
                      position: "absolute",
                      height: "100%",
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6} className="details-grid-box mt-1">
              <div className="slots-remaining">{contest?.contestData?.totalPlayers - contest?.contestData?.totalJoinedPlayers} slots remaining</div>
            </Grid>
            <Grid item xs={6} textAlign="right" className="details-grid-box mt-1">
              <div className="slots-filled">{contest?.contestData?.totalJoinedPlayers} slots filled</div>
            </Grid>
          </Grid>

          <div className="roomidpass-container">
            <Grid container spacing={2} className="details-grid slots-info">
              <Grid
                item
                xs={6}
                textAlign="center"
                className="details-grid-box"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(contest?.contestData?.roomId);
                }}>
                <div className="room-id-label details-label">Room ID</div>
                <div className="room-id-value">
                  {contest?.contestData?.roomId !== "" ? (
                    <>
                      {" "}
                      {
                        contest?.contestData?.roomId?.length >15 ? 
                        <marquee> {contest?.contestData?.roomId}</marquee>
                        :contest?.contestData?.roomId 
                      }{" "}
                      <span>
                        <Clipcopy />
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                textAlign="center"
                className="details-grid-box details-grid-box-1"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(contest?.contestData?.roomPassword);
                }}>
                <div className="password-label details-label">Password</div>
                <div className="room-id-value">
                  {contest?.contestData?.roomPassword !== "" ? (
                    <>
                      {
                        contest?.contestData?.roomPassword?.length >15 ? 
                        <marquee> {contest?.contestData?.roomPassword}</marquee>
                        :contest?.contestData?.roomPassword 
                      }{" "}
                      <span>
                        <Clipcopy />
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        
          </div>
          </div>

        {isPricePointPoolModel && (
        <WinningBreakup
          isOpen={isPricePointPoolModel}
          title="Winning Breakup"
          onClose={() => setPricePointPool(false)}
          prizePool={selectedItem}
          tournaments={selectedItem}
        />
      )}
      </Box>
    </>
  );
};

export default MyContestStructure;

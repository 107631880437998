import { indiggTracker } from "../apis";

const trackerEventId = {
  Game_Types_Page_Shown: "af270226-c328-44fc-a920-6a51f01891b7",
  Tournaments_List_Shown: "daac2166-0090-425f-ab0c-0faa6b9ca024",
  Tournament_Popup_Shown: "5db997db-fa80-4b64-a705-93464ca0b192",
  Join_Tournament_Popup_Shown: "884d9a79-a76b-4dab-b435-4987fc0adf6b",
  Tournament_Details_Shown: "67fcb144-1efa-4fd2-a518-ec26c55a03f5",
  Contest_List_Shown: "6e17d071-4b87-4ace-a299-c66b6019ef81",
  Contest_Join_Popup_Shown: "da04d019-40e5-47ec-b6ee-36aafa00b48d",
  Contest_Share_Button_Click: "e846f852-753a-4e62-9e24-324036c798ad",
  Contest_Details_Shown: "4a1a76e9-82f3-42b6-964e-80d198e540cb",
  Tournament_Share_Button_Click: "c2e63bd3-4635-407c-8361-994f830a3f86",
  Team_Registration_Shown: "2cb520dc-6cc0-4551-a253-a93375aad32b",
};

export const addTrackData = async (eventId,data) => {
  if (localStorage.getItem("profile")) {
    let userData = JSON.parse(localStorage.getItem("profile"));
    data.userId = userData?.userId;
    await addTracker(eventId,data);
  }
};

export const addTracker = async (eventId,data) => {
  console.clear();
  await indiggTracker(eventId,data);
};

export { trackerEventId };

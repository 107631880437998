import React, { useState } from "react";
import Popup from "./basepopup";
import url from "../../constants/url";

const WinningBreakup = ({ isOpen, title, onClose, prizePool, tournaments }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Popup isOpen={isOpen} title={title} onClose={onClose}>
      <div className="popup-container">
        {/* Tabs */}
        <div className="tab-container">
          <div
            key={"PrizePool"}
            className={
              activeTab === 0 ? "tabspop active-tabspop" : "tabspop"
            }
            onClick={() => setActiveTab(0)}
          >
            {"Prize Pool"}
          </div>
          {prizePool?.pointpool?.length > 0 && (
            <div
              key={"PointPool"}
              className={
                activeTab === 1 ? "tabspop active-tabspop" : "tabspop"
              }
              onClick={() => setActiveTab(1)}
            >
              {"Point Pool"}
            </div>
          )}
        </div>

        {/* Conditional Content */}
        <div className="content-container">
  {
    activeTab === 0 ? (
      <div key={"PrizePool"}>
        <div className="prize-pool-title">Prize Pool</div>
        <div className="prize-amount">
          {
            prizePool?.prizePool &&
            <span className="icon me-2">
              <img
                className="icon"
                src={
                  url?.image_url +
                  tournaments?.currency?.[0]?.outCurrency?.img?.default
                }
                alt=""
              />
            </span>
          }
          {prizePool?.prizePool ? prizePool?.prizePool : tournaments?.rewardDisplayText}
        </div>
        <div className={`rank-details ${prizePool?.prizePooInfo?.length > 5 ? 'scrollable' : ''}`}>
          {prizePool?.prizePooInfo && prizePool?.prizePooInfo?.length > 0 ? prizePool?.prizePooInfo?.map((detail, index) => (
            <p className="ranksdet" key={index}>
              Rank: {detail?.rankFrom}{(detail?.rankTo !== '0' && detail?.rankFrom != detail?.rankTo) && "-"}{(detail?.rankTo !== '0' && detail?.rankFrom != detail?.rankTo) && detail?.rankTo}
              <span>
                {
                  detail?.amount &&
                  <span className="icon me-2">
                    <img
                      src={
                        url?.image_url +
                        tournaments?.currency?.[0]?.outCurrency?.img?.default
                      }
                      alt=""
                    />
                  </span>
                }
                <span>{detail?.amount ? detail?.amount : detail?.prize}</span>
              </span>
            </p>
          )) : prizePool?.prizePoolInfo?.map((detail, index) => (
            <p className="ranksdet" key={index}>
              Rank: {detail?.rankFrom}{(detail?.rankTo !== '0' && detail?.rankFrom != detail?.rankTo) && "-"}{(detail?.rankTo !== '0' && detail?.rankFrom != detail?.rankTo) && detail?.rankTo}
              <span>
                {
                  detail?.amount &&
                  <span className="icon me-2">
                    <img
                      src={
                        url?.image_url +
                        tournaments?.currency?.[0]?.outCurrency?.img?.default
                      }
                      alt=""
                    />
                  </span>
                }
                <span>{detail?.amount ? detail?.amount : detail?.prize}</span>
              </span>
            </p>
          ))}
        </div>
      </div>
    ) : (
      <div key={"PointPool"}>
        {/* <div className="prize-pool-title">Point Pool</div> */}
        <div className={`rank-details ${prizePool?.pointpool.length > 5 ? 'scrollable' : ''}`}>
          {prizePool?.pointpool?.map((detail, index) => (
            <p className="ranksdet" key={index}>
              Rank: {detail?.rankFrom}{(detail.rankTo !== '0' && detail.rankFrom != detail.rankTo) && "-"}{(detail.rankTo !== '0' && detail.rankFrom != detail.rankTo) && detail?.rankTo} <span>{detail.point}</span>
            </p>
          ))}
        </div>
      </div>
    )
  }
</div>

      </div>
    </Popup>
  );
};

export default WinningBreakup;

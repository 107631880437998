import React, { useState, useEffect } from "react";
import Popup from "./basepopup";
import moment from "moment";
import { login, indiggTournamentJoin } from "../../apis"
import url from "../../constants/url";
import { useNavigate } from "react-router";
import Loader from "../loader";
import { addTrackData, trackerEventId } from "../../config/tracker";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const TournamentConfirmationPopup = ({ isOpen, title, onClose, tournaments, selectedMatchDate, firstRoundId, uniqueIgn, setIsTournamentConfirmationPopup, setErrorMsg, setShowToast}) => {
    const [currentBalance, setCurrentBalance] = useState(0);
    const [contestDisabled, setContestDisabled] = useState(false);
    const [teamRegistration, setTeamRegistration] = useState(false);
    const [isLoading, setIsLoader] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchData()
        addTrackData(trackerEventId?.Join_Tournament_Popup_Shown,{gameCatalogName:`${localStorage.getItem("selectedGameName")}`,gameCatalogId:`${localStorage.getItem("game")}`,tournamentId:`${tournaments?._id}`});
    }, [])

    const fetchData = async () => {
        setIsLoader(true);
        let res = await login();
        if (res?.success) {
            setIsLoader(false);
            setCurrentBalance(res?.data?.walletBalance)
        }
        else {
            setIsLoader(false);
        }
    }

    const handlerJoinTournament = async () => {
        setIsLoader(true);
        // setContestDisabled(true)
        let data = {
            contest: firstRoundId,
            currencyCode: tournaments?.currency?.[0]?.inCurrency?._id,
            event: tournaments?._id,
            inGameUserName: uniqueIgn,
            isActive: true,
            type: "event",
            uniqueIGN: uniqueIgn,
            user: JSON?.parse(localStorage.getItem('profile'))?.user_id,
            indigg_access_token: localStorage.getItem('accessToken'),
        };
        
        if (tournaments?.entryFee > currentBalance) {
            setIsLoader(false);
            setErrorMsg("Insufficient balance in your account.");
            setIsTournamentConfirmationPopup(false);
            setShowToast(true)
        }
        else {
            if (tournaments?.gameTypeAry?.players > 1) {
                // team registration
                setIsLoader(false);
                data.players = tournaments?.gameTypeAry?.players
                data.extraPlayers = tournaments?.gameTypeAry?.extraPlayers
                data.game_type = tournaments?.gameTypeAry?._id
                localStorage.setItem("registerData", JSON.stringify(data))
                localStorage.setItem("registerType", "tournament")
                navigate("/team-registration");
                setTeamRegistration(true)
            } else {
                try {
                    const res = await indiggTournamentJoin(data);
                    if (res?.success) {
                        setIsLoader(false);
                        setContestDisabled(false)
                        navigate("/tournament/details/" + res?.item?.event);
                    } else {
                        setIsLoader(false);
                        setContestDisabled(false)
                        setErrorMsg(res?.response?.data?.errors?.[0]?.msg || res?.data?.errors?.[0]?.msg);
                        setIsTournamentConfirmationPopup(false);
                        setShowToast(true)
                    }
                } catch (error) {
                    setIsLoader(false);
                    setContestDisabled(false)
                    setErrorMsg(error?.response?.data?.errors?.[0]?.msg);
                    setIsTournamentConfirmationPopup(false);
                    setShowToast(true)
                }
            }
        }
    }

    return (
        isLoading
        ?
        <Loader/> 
        :
        <Popup
            isOpen={isOpen}
            title={tournaments?.title}
            onClose={() => onClose(false)}
        >
            <div className="superpower-team-heading">
                <div className="joingpopup">
                <div className="join-gamename">
                    {tournaments?.gameAry?.name} -{" "}
                    <span style={{ color: "rgba(219, 237, 241, 0.60)" }}>
                    {tournaments?.gameTypeAry?.name}
                    </span>
                </div>

                <div className="joing-pop-datetime">{selectedMatchDate}</div>
                </div>
            </div>
            <div className="superpower-team-confirmation">
                <div className="joinggamename-confirmation-pop">Confirmation </div>
                <div className="joinggamename-balance-pop">
                KCash Balance =
                {" "}
                <span className="icon">
                    <img
                    src={
                        url.image_url +
                        tournaments?.currency?.[0]?.inCurrency?.img?.default
                    }
                    alt=""
                    />
                </span>
                {" "}{currentBalance}
                </div>
            </div>
            <div className="superpower-team-fee">
                <div className="joingpopup">
                <div className="joingamename-pop"> Entry Fee </div>
                    <div className="joing-pop-datetime">
                    {tournaments?.entryFee > 0 ?
                        <>
                            {" "}
                            <span className="icon">
                                <img
                                src={
                                    url.image_url +
                                    tournaments?.currency?.[0]?.inCurrency?.img?.default
                                }
                                alt=""
                                />
                            </span>
                            {" "}
                            {tournaments?.entryFee}
                        </>
                    :
                        "Free"
                    }
                </div>
                </div>
                <div className="joingpopup">
                <div className="joingamename-pop"> Your Fee </div>
                <div className="joing-pop-datetime">
                    {tournaments?.entryFee > 0 ?
                        <>
                            {" "}
                            <span className="icon">
                                <img
                                src={
                                    url.image_url +
                                    tournaments?.currency?.[0]?.inCurrency?.img?.default
                                }
                                alt=""
                                />
                            </span>
                            {" "}
                            {tournaments?.entryFee}
                        </>
                    :
                        "Free"
                    }
                </div>
                </div>
            </div>
            <div className="superpower-team-btn mb-5">
                <button className="popup-button my-contest-button" disabled={contestDisabled} onClick={() => handlerJoinTournament()}>REGISTER</button>
            </div>
        </Popup>
    )
};

export default TournamentConfirmationPopup;
const routehelp = {
    default: "/",
    gametype: "/gametype",
    mycontestlist: "/my-contest/list",
    contestList: "/contest/list/:gameTypeId",
    teamregistration: "/team-registration",
    contestdetails: "/contest/details/:id",
    tournamentlist: "/tournament/list",
    tournamentdetails: "/tournament/details/:id",
    pagenotfound: "/pagenotfound"
  }
  
  export { routehelp };
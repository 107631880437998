import React from 'react'

const ToastPopup = ({message, setShowToast}) => {
  return (
    <div className='overlay-active' onClick={()=> setShowToast(false)}>
    <div className="toast-container ">
    <div className="toast-message">{message}</div>
    </div>
  </div>
  )
}

export default ToastPopup
import React from "react";

function Loader(props) {
  return (
    <div className="loader" style={{}}>
      {/* <ReactSpinner type="border" color='#212529' size="3" /> */}
      {/* <Lottie
        // resizeMode="cover"
        // style={{ height: "100%" }}
        options={{
          animationData: loderAnimation,
          // h:"100%"
        }}
      /> */}
      <div class="full-page-loader" id="loader-1"></div>
    </div>
  );
}

export default Loader;
